// import React from "react";
import { CheckableStyleButton } from "./CheckableButton.styles";

const CheckableButton = ({currency, price, clickHandler, selected, body, isActive=true}) => {
	return (
		<CheckableStyleButton isActive={isActive} isChecked={selected} onClick={clickHandler}>
			{
				currency !== undefined && price !== undefined ? <div id="title_parent"><span id="title">{`${currency}${price} per ${body}`}</span></div> : 
					<div id="title_parent"><span id="title">{`${body}`}</span></div>
			}
      
	  <div id="icon">
				<div id="check"/>
	  </div>
		</CheckableStyleButton>
	);
};

export default CheckableButton;

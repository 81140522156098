import {useEffect} from "react";
 import {useDispatch} from "react-redux";
// import { useNavigate } from "react-router-dom";
// import { PleaseWait } from "./AuthRedirect.style";
// import { subscriptions, appState} from "../../utils/redux/actions";
// import { PWA_ROUTES } from "../../constants/appConstants";
// import storage from "redux-persist/lib/storage";
 import { useAuth0 } from "@auth0/auth0-react";
 import loaderskel from "../../assets/images/loader.gif";
 import { AuthenticationScreen as events } from "../../analytics/events";

const LoginRedirect = () => {
	 const {  loginWithRedirect } = useAuth0();
     const dispatch = useDispatch();

// const width = window.innerWidth;
const height = window.innerHeight;

     const decodeJwt = (token) => {
        var base64Payload = token.split(".")[1];
        var payloadBuffer = Buffer.from(base64Payload, "base64");
        return JSON.parse(payloadBuffer.toString());
    };

    const getQueryParams = (url) => {
        const paramArr = url.slice(url.indexOf("?") + 1).split("&");
        const params = {};
        paramArr.map(param => {
            const [key, val] = param.split("=");
            params[key] = decodeURIComponent(val);
        });
        return params;
    };


    const getSourceOriginPack = () => {
        const url = window.location.href;
        const searchParams = getQueryParams(url);
        return searchParams;
    };

    const mtnLiteInstanceFlow = () => {
        const queryString = getSourceOriginPack();
        const ObjectPack = decodeJwt(queryString?.t);
        localStorage.setItem("mtnlite", true);


        loginWithRedirect({redirectUri:`${window.location.origin}/auth/callback`, login_hint: ObjectPack});
        dispatch({
            type: "ANALYTICS/TRACK_EVENT",
            event: events.login_authentication(
                "mtnlite"
            ),
          });
    };


	// const dispatch = useDispatch();
	// const navigate = useNavigate();
	// const [searchParams] = useSearchParams();

	// const restoreSelectedItemState = (savedState) => {
	// 	if(savedState===null||savedState===undefined||savedState==="") return navigate(PWA_ROUTES.HOME);

	// 	const stateJson = JSON.parse(savedState);
        
	// 	if (stateJson.extra !== undefined) {
    //   dispatch(subscriptions.selectCharge(stateJson?.extra?.selectedCharge));
    //   dispatch(subscriptions.selectItem(stateJson?.extra?.item));
    //   dispatch(appState.onMediaSelectedState(stateJson?.extra?.item));

    //   storage.removeItem("state");
    // }

	// 	navigate(stateJson.route);
	// };

	useEffect(()=>{
		// const savedState = localStorage.getItem("state");
		// restoreSelectedItemState(savedState);

        mtnLiteInstanceFlow();


	}, []);
	
	return (
		<div style={{justifyContent: "center", alignItems: "center", display: "flex", marginTop:height/2}}>
                <img  alt="" width={100} height={100} src={loaderskel} />
		</div>
	);
};

export default LoginRedirect;
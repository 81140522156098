import { language } from "../types";

import  englishLan  from "../../../languagePack/english.json";
import  frenchLan  from "../../../languagePack/french.json";
import  arabicLan  from "../../../languagePack/arabic.json";

const initialState = {
	chosenLanguage: "",
	chosenPack: null
};

const languageReducer = (state = initialState, action) => {
	let packHolder = action.payload;
	if(action.payload === "english") {
		packHolder = englishLan;
	}
	if(action.payload === "french") {
		packHolder = frenchLan;
	}
	if(action.payload === "arabic") {
		packHolder = arabicLan;
	}
	switch (action.type) {
	case language.SET_COUNTRY_SUCCESS:
		return {
			...state,
			chosenLanguage: action.payload,
			chosenPack: packHolder
		};
               
	default:
		return state;
	}
};

export default languageReducer;
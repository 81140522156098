import {checkNull, EVENT, PARAM} from "./helpers";

export const myplay_screen = {
	eventName: EVENT.VIEW_SCREEN,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "discovery",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "myplay",
		},
	],
};



export const content_player_screen = (
	content_category,
	content_title,
	content_type,
	content_vendor,
) => {
	return {
		eventName: EVENT.VIEW_SCREEN,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "discovery",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "content-player",
			},
			{
				key: PARAM.KEY.CONTENT_CATEGORY,
				value: checkNull(content_category),
			},
			{
				key: PARAM.KEY.CONTENT_TITLE,
				value: checkNull(content_title),
			},
			{
				key: PARAM.KEY.CONTENT_PUBLISHER,
				value: checkNull(content_type),
			},
			{
				key: PARAM.KEY.CONTENT_VENDOR,
				value: checkNull(content_vendor),
			},
		],
	};
};

import axios from "axios";
import { subscriptions } from "../types";
import { uri } from "../../../constants/appConstants";
// import { useNavigate } from "react-router-dom";
// import { createBrowserHistory } from 'history'
export const fetchAllPurchases = (call) => async(dispatch) => {
	// const navigate = useNavigate();
	dispatch({
		type: subscriptions.FETCH_PURCHASE_LIST_REQUEST,
		payload: true
	});

	try {
		const response = await call?.fetchAllPurchases();
		if(response){
			dispatch({
				type: subscriptions.FETCH_PURCHASE_LIST_SUCCESS,
				payload: response,
			});

			return;
		}

	} catch (error) {
		dispatch({
			type: subscriptions.FETCH_PURCHASE_LIST_ERROR,
			payload: {
				message: error?.message,
				error
			}
		});
		// createBrowserHistory.push('./generic')
	}

};

export const fetchAllSubscriptions = (call) => async(dispatch) => {
    
	dispatch({
		type: subscriptions.FETCH_SUBSCRIPTION_LIST_REQUEST,
		payload: true
	});
	try { 
		const response = await call?.fetchAllSubscriptions();
		if(response){
			dispatch({
				type: subscriptions.FETCH_SUBSCRIPTION_LIST_SUCCESS,
				payload: response,
			});

			return;
		}


	} catch (error) {

		dispatch({
			type: subscriptions.FETCH_SUBSCRIPTION_LIST_ERROR,
			payload: {
				message: error?.message,
				error
			}
		});
		// createBrowserHistory.push('./generic')
	}
};

export const selectItem = (item, selectedSource) => async (dispatch) => {
  const _payload = {
    ...item,
    selectedSource: selectedSource ? selectedSource : item?.selectedSource,
  };
  dispatch({
    type: subscriptions.FETCH_SUBSCRIPTION_SUCCESS,
    payload: _payload,
  });
};

export const saveConsent = (call, consent) => async (dispatch) => {
	dispatch({
		type: subscriptions.SELECT_CONSENT_REQUEST,
	});

	try {
		const response = await call?.saveConsent(consent);

		dispatch({
			type: subscriptions.SELECT_CONSENT_SUCCESS,
			payload: response,
		});
	} catch (error) {
		dispatch({
			type: subscriptions.SELECT_CONSENT_ERROR,
			payload: {
				message: error?.message,
				error,
			},
		});
	}
};

export const clearConsent = () => async(dispatch) =>{
	dispatch({
		type: subscriptions.CLEAR_CONSENT,
	});
};


export const selectService = (item, selectedSource) => async (dispatch) => {
  const _payload = {
    ...item,
    selectedSource: selectedSource ? selectedSource : item?.selectedSource,
  };
  dispatch({
    type: subscriptions.SELECT_SERVICE_SUCCESS,
    payload: _payload,
  });
};

export const selectCharge = (charge) => async(dispatch) => {

	dispatch({
		type: subscriptions.SELECT_SUBSCRIPTION_CHARGE_REQUEST,
		payload: charge
	});

	try {
		/**
         * simulate the return from the api
         */
		const selectedCharge = charge; 

	  dispatch({
			type: subscriptions.SELECT_SUBSCRIPTION_CHARGE_SUCCESS,
			payload: selectedCharge
		});

	} catch (error) {

		dispatch({
			type: subscriptions.SELECT_SUBSCRIPTION_CHARGE_ERROR,
			payload: {
				message: error?.message,
				error
			}
		});
		// createBrowserHistory.push('./generic')
	}
};

export const selectPaymentMethod = (method) => async(dispatch) => {

	dispatch({
		type: subscriptions.SELECT_PAYMENT_METHOD_REQUEST,
		payload: method
	});

	try {
		/**
         * simulate the return from the api
         */
		const selectedMethod = method; 

	  dispatch({
			type: subscriptions.SELECT_PAYMENT_METHOD_SUCCESS,
			payload: selectedMethod
		});

	} catch (error) {

		dispatch({
			type: subscriptions.SELECT_PAYMENT_METHOD_ERROR,
			payload: {
				message: error?.message,
				error
			}
		});
		// createBrowserHistory.push('./generic')
	}
};

export const clearSubscription = () => async(dispatch) =>{
	dispatch({
		type: subscriptions.CLEAR_SUBSCRIPTION
	});
};

export const resetUnsubscription = () => async(dispatch) =>{
	dispatch({
		type: subscriptions.RESET_SUBSCRIPTION
	});
};

export const buyCurrentItem = (call, item, msisdn) => async(dispatch, getState) => { 

	dispatch({type: subscriptions.BUY_ITEM_REQUEST});
	try { 
		const {selectedCharge, selectedItem} = getState().subscriptions;
		const incoming = {
			AutoRenew: item.subscriptionRenew,
		};
		// const paymentModel = {
		// 	...selectedCharge,
		// 	...incoming
		// };
		const sItem = { itemType: selectedItem.itemType};
		const persistedModel = {
			...selectedCharge,
			...incoming,
			...sItem
		};
  
		const response = await call?.buyCurrentItem(item, msisdn);
		if(response){
			dispatch({
				type: subscriptions.BUY_ITEM_SUCCESS,
				payload: response,
				selectedModel: persistedModel
			});
		}
		dispatch({
			type: subscriptions.BUY_ITEM_ERROR,
			payload: "An error occured, pls try again."
		});
	} catch (error) {
		console.log("errroooorr33",error);
		dispatch({
			type: subscriptions.BUY_ITEM_ERROR,
			payload: error?.response
		});
	}
};

export const unsubscribe = (call, item) => async(dispatch) => {


	dispatch({type: subscriptions.UN_SUBSCRIBE_ITEM_REQUEST});
	try {
		const response = await call?.unsubscribe(item);
		if(response){
			dispatch({
				type: subscriptions.UN_SUBSCRIBE_ITEM_SUCCESS,
				payload: response
			});

			return;
		}

		dispatch({
			type: subscriptions.UN_SUBSCRIBE_ITEM_REQUEST,
			payload: true
		});

	} catch (error) {
		dispatch({
			type: subscriptions.UN_SUBSCRIBE_ITEM_ERROR,
			payload: error?.response
		});
	}
};

export const fetchPaymentMethods = (call) => async(dispatch) => {
	dispatch({ type: subscriptions.FETCH_PAYMENT_METHODS_REQUEST });

	try { 
		const response = await call?.fetchPaymentMethods();
		if(response){
			dispatch({
				type: subscriptions.FETCH_PAYMENT_METHODS_SUCCESS,
				payload: response
			});

			return;
		}
	} catch (error) {
		dispatch({
			type: subscriptions.FETCH_PAYMENT_METHODS_ERROR,
			payload: {
				message: error?.message,
				error
			}
		});
	}

};

export const fetchTopUpOtions = () => async(dispatch) => {

	dispatch({
		type: subscriptions.FETCH_TOPUP_OPTIONS_REQUEST,
		payload: true
	 });

	try {
		const response = await axios.get(`${uri}/api/topup-options`);
		const apiResponse = response.data || null;
        
		dispatch({
			type: subscriptions.FETCH_TOPUP_OPTIONS_SUCCESS,
			payload: apiResponse
		});
	} catch (error) {

		dispatch({
			type: subscriptions.FETCH_TOPUP_OPTIONS_ERROR,
			payload: {
				message: error?.message,
				error
			}
		});
	}

};

export const fetchBalances = () => async(dispatch, getState) => {
	const {selectedCharge, selectedItem} = getState().subscriptions;

	dispatch({
		type: subscriptions.FETCH_BALANCES_REQUEST,
		payload: true
	});

	try {
		const response = await axios.get(`${uri}/api/calculate-payments/${selectedItem?.id}/${selectedCharge?.value}`);
		const apiResponse = response.data || null;
      
		dispatch({
			type: subscriptions.FETCH_BALANCES_SUCCESS,
			payload: apiResponse
		});
	} catch (error) {

		dispatch({
			type: subscriptions.FETCH_BALANCES_ERROR,
			payload: {
				message: error?.message,
				error
			}
		});
	}

};

export const fetchUserSubscriptions = (call) => async(dispatch) => {

	dispatch({
		type: subscriptions.FETCH_USER_SUBSCRIPTION_REQUEST,
		payload: true
	});

	try {
		const response = await call?.fetchUserSubscriptions();     

		if(response==null || response == undefined) return;

		dispatch({
			type: subscriptions.FETCH_USER_SUBSCRIPTION_SUCCESS,
			payload: response
		});
	} catch (error) {

		dispatch({
			type: subscriptions.FETCH_USER_SUBSCRIPTION_ERROR,
			payload: {
				message: error?.message,
				error
			}
		});
	}

};

export const fetchUserPurchases = (call) => async(dispatch) => {

	dispatch({
		type: subscriptions.FETCH_USER_PURCHASE_REQUEST,
		payload: true
	});

	try {
		const response = await call?.fetchUserPurchases();

		if(response==null || response == undefined) return;

		dispatch({
			type: subscriptions.FETCH_USER_PURCHASE_SUCCESS,
			payload: response
		});
	} catch (error) {

		dispatch({
			type: subscriptions.FETCH_USER_PURCHASE_ERROR,
			payload: {
				message: error?.message,
				error
			}
		});
	}

};

export const topupVoucher = (voucher) => async(dispatch) => {    
	const topupModel = {
		Code: voucher
	};

	dispatch({
		type: subscriptions.VOUCHER_TOPUP_REQUEST,
		payload: true
	 });

	try {

		const response = await axios.post(`${uri}/api/topup`, topupModel);
		const apiResponse = response.data || null;
		dispatch({
			type: subscriptions.VOUCHER_TOPUP_SUCCESS,
			payload: apiResponse
		});
	} catch (error) {
		dispatch({
			type: subscriptions.VOUCHER_TOPUP_ERROR,
			payload: {
				message: error?.response?.data?.message,
				error
			}
		}); 
	}

};
import {EVENT, PARAM} from "./helpers";

export const authenticationAttempt = {
	eventName: EVENT.VIEW_SCREEN,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "authenticate_request",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "content_detail",
		},
	],
};

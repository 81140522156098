import {checkNull, EVENT, PARAM} from "./helpers";

export const help_topics_list_screen = {
	eventName: EVENT.VIEW_SCREEN,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "discovery",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "topics_list",
		},
	],
};

export const expand_faq_item = (title) => {
	return {
		eventName: EVENT.TAP,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "help",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "topics_list",
			},
			{
				key: PARAM.KEY.FAQ_TITLE,
				value: checkNull(title),
			},
			{
				key: PARAM.KEY.TAP_TARGET,
				value: "faq_item",
			},
		],
	};
};

export const help_content_item_screen = (category, title) => {
	return {
		eventName: EVENT.VIEW_SCREEN,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "help",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "topics_list",
			},
			{
				key: PARAM.KEY.CONTENT_CATEGORY,
				value: checkNull(category),
			},
			{
				key: PARAM.KEY.CONTENT_TITLE,
				value: checkNull(title),
			},
		],
	};
};

import {useState,useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
// import { useLocation, useNavigate } from "react-router-dom";
import {  useNavigate } from "react-router-dom";
import { bottomTabItems } from "../../../utils/mock";
import { useAuth0 } from "@auth0/auth0-react";
import {
  StyledBottomBar,
  StyledBottomBarItem,
  StyledBottomBarItemIcon,
  StyledBottomBarItemName,
} from "./BottomBar.styles";
import { subscriptions as subAction } from "../../../utils/redux/actions";
import HelperFunctions from "../../../utils/HelperFunctions";
import { PWA_ROUTES } from "../../../constants/appConstants";
const helperFunction = new HelperFunctions();

const BottomBar = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();

  const { items, language } = useSelector((state) => state);
  const { pendingItem } = useSelector((state) => state?.subscriptions);
  const {
		playInstanceItems,
	} = useSelector((state) => state?.items);

  const dispatch = useDispatch();
  // const { pathname } = useLocation();

  const [barItems, setBarItems] = useState(bottomTabItems);
  const [showingPending, setShowPending] = useState(false);

  useEffect(() => {
    initNavigation();
  }, []);

  /**
   * Zigi ChatBot Bubble must be visible only on the Manage Screen
   */
  // useEffect(() => {
  //   let zigiChatBotWrapper = document.querySelector("#mtnbot-container");
  //   if (zigiChatBotWrapper) {
  //     if (pathname === "/settings") {
  //       zigiChatBotWrapper.classList.remove("hide");
  //     } else {
  //       zigiChatBotWrapper.classList.add("hide");
  //     }
  //   }
  // }, [pathname]);

  useEffect(() => {
    let canShowPending = false;
    helperFunction.subsStatusFilterUtil(
      items?.items,
      pendingItem?.subscriptionId,
      (resp) => {
        canShowPending = resp == "Pending";
      },
    );

    setShowPending(canShowPending);
  }, [items?.items]);

  // useEffect(() => {

  // 	if (network?.call !== null || network?.call !== undefined || network?.call !== '') {
  // 		if(network?.call?.fetchAllContent && (network?.call?.headers?.region !== null  || network?.call?.headers?.region !== undefined || network?.call?.headers?.region !== '')) {
  // 			if(network?.call?.headers?.region) {
  // 				dispatch(fetchItems(network?.call));
  // 			}

  // 		}
  // 	}
  // }, [pendingItem?.subscriptionId, network]);

  const initNavigation = () => {
    const newArr = barItems.map((obj) => {
      if (location.pathname === obj.path) {
        return { ...obj, active: true };
      }

      return obj;
    });
    setBarItems(newArr);
  };

  const BottomBarItem = ({ name, icon, path, icon_active, index, active, noIcon }) => {
    const navigate = useNavigate();

    const itemClicked = () => {
      if (!isAuthenticated && path === PWA_ROUTES.MY_PLAY) {
        localStorage.setItem(
          "state",
          JSON.stringify({ route: `${path}`, extra: undefined }),
        );
        loginWithRedirect({
          redirectUri: `${window.location.origin}/auth/callback`,
        });
        return;
      }

      dispatch(subAction.selectItem(null));
      const newArr = barItems.map((obj) => {
        if (path === obj.path) {
          return { ...obj, active: true };
        }
        return obj;
      });
      setBarItems(newArr);
      let barItemsClone = newArr;
      for (let i = 0; i < barItemsClone.length; i++) {
        if (barItemsClone[i] === barItemsClone[index]) {
          barItemsClone[index].active = true;
        } else {
          barItemsClone[i].active = false;
        }
      }

      // barItemsClone = barItemsClone;
      setBarItems(barItemsClone);
      navigate(path);
    };

    return (
      <StyledBottomBarItem onClick={itemClicked}>
        <StyledBottomBarItemIcon
          className="icon"
          icon={ active ? icon_active :  playInstanceItems?.message !== "full"? noIcon :  icon }>
          {name === "manage" && showingPending === true ? (
            <div
              style={{
                paddingTop: "2px",
                marginTop: "-8px",
                marginLeft: "10px",
                flex: 1,
                alignItems: "center",
                alignContent: "center",
                justifyContent: "center",
                textAlign: "center",
                height: "15px",
                width: "15px",
                borderRadius: "10px",
                backgroundColor: "#DC6428",
                color: "white",
                fontSize: "8px",
              }}>
              1
            </div>
          ) : (
            <></>
          )}
          
        </StyledBottomBarItemIcon>
        <StyledBottomBarItemName active={active}>
          {name === "Home"
            ? language?.chosenPack?.language?.COMPONENTS?.bottom_menu?.home
            : name === "my play" && playInstanceItems?.message === "full"
            ? language?.chosenPack?.language?.COMPONENTS?.bottom_menu?.my_play
            : name === "my play" && playInstanceItems?.message !== "full"
            ? ""
            : language?.chosenPack?.language?.COMPONENTS?.bottom_menu?.manage}
        </StyledBottomBarItemName>
      </StyledBottomBarItem>
    );
  };

  const renderBottomTabs = (items) => {
    return items.map((item, index) => (
      <BottomBarItem
        key={item?.id}
        name={item?.name}
        path={item?.path}
        icon={item?.icon}
        icon_active={item?.icon_active}
        noIcon={item?.noIcon}
        index={index}
        active={item?.active}
      />
    ));
  };

  return <StyledBottomBar>{renderBottomTabs(barItems)}</StyledBottomBar>;
};

export default BottomBar;
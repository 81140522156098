import {checkNull, EVENT, PARAM} from "./helpers";

export const content_detail_screen = (
	content_category,
	content_title,
	content_price_tier,
	content_vendor,
	content_vendor_title
) => {
	return {
		eventName: EVENT.VIEW_SCREEN,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "discovery",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "content_detail",
			},
			{
				key: PARAM.KEY.CONTENT_CATEGORY,
				value: checkNull(content_category),
			},
			{
				key: PARAM.KEY.CONTENT_TITLE,
				value: checkNull(content_title),
			},
			{
				key: PARAM.KEY.CONTENT_PRICE_TIER,
				value: checkNull(content_price_tier),
			},
			{
				key: PARAM.KEY.CONTENT_VENDOR,
				value: checkNull(content_vendor),
			},
			{
				key: PARAM.KEY.SERVICE_PROVIDER,
				value: checkNull(content_vendor_title),
			},

			
		],
	};
};




export const like_tap = (
	content_category,
	content_title,
	content_price_tier,
	content_vendor,
	content_provider_name
) => {
	return {
		eventName: EVENT.TAP,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "like_tap",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "content_detail",
			},
			{
				key: PARAM.KEY.CONTENT_CATEGORY,
				value: checkNull(content_category),
			},
			{
				key: PARAM.KEY.CONTENT_TITLE,
				value: checkNull(content_title),
			},
			{
				key: PARAM.KEY.CONTENT_PRICE_TIER,
				value: checkNull(content_price_tier),
			},
			{
				key: PARAM.KEY.CONTENT_VENDOR,
				value: checkNull(content_vendor),
			},
			{
				key: PARAM.KEY.SERVICE_PROVIDER,
				value: checkNull(content_provider_name),
			},
		],
	};
};


export const content_detail_insufficient_funds_error = (
	content_category,
	content_title,
	content_price_tier,
	error_message,
) => {
	return {
		eventName: EVENT.ERROR,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "discovery",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "content_detail",
			},
			{
				key: PARAM.KEY.CONTENT_CATEGORY,
				value: checkNull(content_category),
			},
			{
				key: PARAM.KEY.CONTENT_TITLE,
				value: checkNull(content_title),
			},
			{
				key: PARAM.KEY.CONTENT_PRICE_TIER,
				value: checkNull(content_price_tier),
			},
			{
				key: PARAM.KEY.ERROR_MESSAGE,
				value: checkNull(error_message),
			},
			{
				key: PARAM.KEY.ERROR_CODE,
				value: "insufficient_funds",
			},
		],
	};
};



export const play_content = (
	content_category,
	content_title,
	content_price_tier,
	content_vendor,
	content_vendor_title
) => {
	return {
		eventName: EVENT.VIEW_SCREEN,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "play",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "play_content_request",
			},
			{
				key: PARAM.KEY.CONTENT_CATEGORY,
				value: checkNull(content_category),
			},
			{
				key: PARAM.KEY.CONTENT_TITLE,
				value: checkNull(content_title),
			},
			{
				key: PARAM.KEY.CONTENT_PRICE_TIER,
				value: checkNull(content_price_tier),
			},
			{
				key: PARAM.KEY.CONTENT_VENDOR,
				value: checkNull(content_vendor),
			},
			{
				key: PARAM.KEY.SERVICE_PROVIDER,
				value: checkNull(content_vendor_title ),
			},

		],
	};
};



export const play_content_manage = (
	content_vendor,
	service_provider
) => {
	return {
		eventName: EVENT.VIEW_SCREEN,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "play_manage",
			},
			{
				key: PARAM.KEY.SCREEN,
				value: "play_content_request_manage",
			},
			{
				key: PARAM.KEY.CONTENT_VENDOR,
				value: checkNull(content_vendor),
			},
	
			{
				key: PARAM.KEY.SERVICE_PROVIDER,
				value: checkNull(service_provider),
			},


		],
	};
};

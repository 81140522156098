/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { PWA_ROUTES } from "../../constants/appConstants";

import {
  Page,
  TopNav,
  ContentTopNav,
  ItemInfo,
  SubscribeSection,
} from "../../shared/components";
import { ItemSelected } from "../../HOC";
import { ContentInfoScreen as events } from "../../analytics/events";
import { useAuth0 } from "@auth0/auth0-react";

import "react-html5video/dist/styles.css";
import { useNavigate } from "react-router-dom";
import { StyledSubscribeSection } from "../../shared/components/SubscribeSection/SubscribeSection.styles";
import styled from "styled-components";
import ApiCall from "../../utils/network/apiCalls";
import { selectItem } from "../../utils/redux/actions/subscription.action";

const ServiceDetailsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth0();

  const apiCall = new ApiCall();

  // const {
  // 	redirectUrlObj,
  // 	error
  // } = useSelector((state) => state?.items);

  const { profile } = useSelector((state) => state);
  const { subscriptions } = useSelector((state) => state);
  const {
		playInstanceItems,
	  } = useSelector((state) => state?.items);

  const [redirectUrl, setRedirectUrl] = useState(undefined);

  const [redirectUrlPass, setRedirectUrlPass] = useState(undefined);

  const hasAuthentication = (function checkAuthenticationState() {
    if (isAuthenticated == true) {
      return true;
    }
    if (profile?.data?.msisdn != undefined && profile?.data?.msisdn != null) {
      return true;
    }

    return false;
  })();

	const selectedItem = subscriptions?.selectedItem;

  // useEffect(() => {
  //   setRedirectUrl(undefined);

  //   const url = new URL(window.location.href);
  //   const s = Array.from(url.searchParams.keys());
  //   if (s.includes("region") && s.includes("id")) {
  //     apiCall
  //       .fetchSharedContent(
  //         url.searchParams.get("id"),
  //         url.searchParams.get("region"),
  //         profile?.data?.msisdn ?? "",
  //       )
  //       .then((res) => {
  //         dispatch(selectItem(res.response,));
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         navigate(PWA_ROUTES.HOME);
  //       });
  //   } else {
  //     if (selectedItem === undefined || selectedItem === null) {
  //       navigate(PWA_ROUTES.HOME);
  //       return;
  //     }

  //     if (selectedItem.url.includes("?")) {
  //       setRedirectUrlPass(
  //         selectedItem?.url +
  //           "&msisdn=" +
  //           String(profile?.data?.msisdn).replace("+", "").trim(),
  //       );
  //     } else {
  //       try {
  //         let a = new URL(selectedItem?.url);
  //         a.searchParams.set(
  //           "msisdn",
  //           String(profile?.data?.msisdn).replace("+", "").trim(),
  //         );
  //         setRedirectUrlPass(a.toString());
  //       } catch (e) {
  //         setRedirectUrlPass("");
  //       }
  //     }
  //   }
  // }, []);

  // useEffect(()=>{
  //   if(redirectUrlObj !== null) {
  //      setRedirectUrl(redirectUrlObj.redirect_to)
  //   }

  // },[redirectUrlObj]);

  useEffect(() => {
    if (redirectUrlPass !== undefined) {
      setRedirectUrl(redirectUrlPass);
    }
  }, [redirectUrlPass]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });

    dispatch({
      type: "ANALYTICS/TRACK_EVENT",
      event: events.content_detail_screen(
        selectedItem?.itemType,
        selectedItem?.title,
        selectedItem?.billingTypes[0].subscriptionType,
        selectedItem?.vendorId,
        selectedItem?.vendor?.title,
      ),
    });
  }, []);

  const determineButtonOutcome = (mSelectedItem) => {
    const result = {};
    result.buttonText =
      mSelectedItem?.billingTypes[0].subscriptionType === "subscription" &&
      (mSelectedItem?.subscribed === "Inactive" ||
        mSelectedItem?.subscribed === "Pending")
        ? "Subscribe to watch"
        : mSelectedItem?.billingTypes[0].subscriptionType === "free"
        ? "watch for free"
        : mSelectedItem?.subscribed === "Active"
        ? "watch now"
        : mSelectedItem?.billingTypes[0].subscriptionType === "subscription"
        ? "Subscribe to watch"
        : "Purchase";
    return result;
  };

  const analyticsWatch = () => {
    dispatch({
      type: "ANALYTICS/TRACK_EVENT",
      event: events.play_content(
        selectedItem?.itemType,
        selectedItem?.title,
        selectedItem?.billingTypes[0].subscriptionType,
        selectedItem?.vendorId,
        selectedItem?.vendor.title,
      ),
    });
  };

  const onSearchQueryChanged = () => {};

  return (
    <ItemSelected probation={true}>
      <Page isBottomBar={false}>
        <TopNav
          title={selectedItem?.service?.name || selectedItem?.name}
          searchInputCallBack={(query) => onSearchQueryChanged(query)}
          enableSearch={false}
          showCloseIcon={true}
        />
        <div
          style={{
            textAlign: "center",
            paddingTop: 35,
            paddingBottom: 10,
            display: "flex",
            justifyContent: "center",
          }}>
          <div
            style={{
              backgroundImage: `url(${
                selectedItem?.service?.logo || selectedItem?.logo
              })`,
              height: 196,
              width: 150,
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              backgroundPosition: "center",
            }}
          />
        </div>
        <div style={{ padding: 24, paddingTop: 0 }}>
          <p>
            Provided by{" "}
            {selectedItem?.vendor?.title ||
              selectedItem?.billingTypes[0]?.vendorName}
          </p>
          <p style={{ opacity: 0.6, lineHeight: "20px", marginTop: 10 }}>
            {selectedItem?.service?.detailedDescription ||
              selectedItem?.detailedDescription}
          </p>
        </div>
        {/* selectedItem.redirectFlow */}
        {selectedItem?.subscribed !== "Pending" && (
          <div>
            {playInstanceItems?.message === "full" && !hasAuthentication ? (
              <SubscribeSection
                buttonText={"Login To Subscribe"}
                className="bottom"
              />
            ) : (
              playInstanceItems?.message === "full" && selectedItem?.subscribed !== "Active" && (
                <SubscribeSection
                  {...determineButtonOutcome(selectedItem)}
                  className="bottom"
                />
              )
            )}
            {/* {!hasAuthentication ? (
              <SubscribeSection
                buttonText={"Login To Subscribe"}
                className="bottom"
              />
            ) : (
              selectedItem?.subscribed !== "Active" && (
                <SubscribeSection
                  {...determineButtonOutcome(selectedItem)}
                  className="bottom"
                />
              )
            )} */}
            {redirectUrl !== undefined &&
              !selectedItem?.internalPlay &&
              redirectUrlPass !== undefined &&
              selectedItem?.subscribed === "Active" && (
                <StyledSubscribeSection>
                  <div
                    style={{
                      textDecoration: "none",
                      color: "#ffff",
                      width: "100%",
                      marginLeft: "24px",
                      marginRight: "24px",
                    }}>
                    <p
                      style={{
                        color: "#ffff",
                        width: "100%",
                        textAlign: "center",
                        marginBottom: "20px",
                      }}>
                      Your content will open in a new window. Please allow
                      pop-ups in your browser.
                    </p>
                    <a
                      onClick={() => {
                        analyticsWatch();
                      }}
                      style={{
                        borderRadius: 40,
                        height: 40,
                        backgroundColor: "#FFCB05",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#000",
                        marginBottom: "20px",
                      }}
                      href={redirectUrlPass}
                      target="_blank"
                      rel="noopener noreferrer">
                      {" "}
                      PLAY NOW
                    </a>
                  </div>
                </StyledSubscribeSection>
              )}

            {redirectUrl !== undefined &&
              !selectedItem?.internalPlay &&
              redirectUrlPass !== undefined &&
              selectedItem?.subscribed === "Active" && (
                <StyledSubscribeSection>
                  <div
                    style={{
                      textDecoration: "none",
                      color: "#ffff",
                      width: "100%",
                      marginLeft: "24px",
                      marginRight: "24px",
                    }}>
                    <p
                      style={{
                        color: "#ffff",
                        width: "100%",
                        textAlign: "center",
                        marginBottom: "20px",
                      }}>
                      Your content will open in a new window. Please allow
                      pop-ups in your browser.
                    </p>
                    <a
                      onClick={() => {
                        analyticsWatch();
                      }}
                      style={{
                        borderRadius: 40,
                        height: 40,
                        backgroundColor: "#FFCB05",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#000",
                        marginBottom: "20px",
                      }}
                      href={redirectUrlPass}
                      target="_blank"
                      rel="noopener noreferrer">
                      {" "}
                      PLAY NOW
                    </a>
                  </div>
                </StyledSubscribeSection>
              )}
            {redirectUrl === undefined &&
              !selectedItem?.internalPlay &&
              redirectUrlPass !== undefined &&
              selectedItem?.subscribed === "Active" && (
                <StyledSubscribeSection>
                  <div
                    style={{
                      textDecoration: "none",
                      color: "#ffff",
                      width: "100%",
                      marginLeft: "24px",
                      marginRight: "24px",
                    }}>
                    <a
                      style={{
                        borderRadius: 40,
                        height: 40,
                        backgroundColor: "grey",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#000",
                      }}
                      href={redirectUrlPass}
                      target="_blank"
                      rel="noopener noreferrer">
                      {" "}
                      <StyledButtonLoading></StyledButtonLoading>
                    </a>
                  </div>
                </StyledSubscribeSection>
              )}
          </div>
        )}
      </Page>
    </ItemSelected>
  );
};

const StyledButtonLoading = styled.div`
  width: 30px;
  height: 30px;
  border: 6px solid ${({ primary }) => (primary ? "#fff" : "#333")};
  border-bottom-color: transparent;
  border-radius: 45px;
  display: flex;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default ServiceDetailsScreen;

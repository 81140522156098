/* eslint-disable no-unused-vars */
import EllipsisText from "react-ellipsis-text";
import { StyledService } from "./Service.styles";
import { useNavigate } from "react-router-dom";
import { PWA_ROUTES } from "../../../constants/appConstants";

const Service = ({ name, logo, description, id }) => {
  const navigate = useNavigate();
  return (
    <StyledService logo={logo}>
      <div className="logo"></div>
      <div className="info">
        <span className="welcome-action">{`Provided by ${name}`}</span>
        <div style={{ opacity: 0.6 }}>
          {/* <EllipsisText length={89} text={description} /> */}
          <span
            className="learn-more"
            id={id}
            onClick={() => navigate(PWA_ROUTES.SERVICE_DETAILS_SCREEN)}>
            Learn more
          </span>
        </div>
      </div>
    </StyledService>
  );
};

export default Service;

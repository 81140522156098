import {EVENT, PARAM} from "./helpers";

export const categories_screen = {
	eventName: EVENT.VIEW_SCREEN,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "discovery",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "categories",
		},
	],
};

export const video_category_screen = {
	eventName: EVENT.VIEW_SCREEN,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "discovery",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "category",
		},
		{
			key: PARAM.KEY.CONTENT_CATEGORY,
			value: "video",
		},
	],
};

export const music_category_screen = {
	eventName: EVENT.VIEW_SCREEN,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "discovery",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "category",
		},
		{
			key: PARAM.KEY.CONTENT_CATEGORY,
			value: "music",
		},
	],
};

export const games_category_screen = {
	eventName: EVENT.VIEW_SCREEN,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "discovery",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "category",
		},
		{
			key: PARAM.KEY.CONTENT_CATEGORY,
			value: "games",
		},
	],
};

export const sports_category_screen = {
	eventName: EVENT.VIEW_SCREEN,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "discovery",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "category",
		},
		{
			key: PARAM.KEY.CONTENT_CATEGORY,
			value: "sports",
		},
	],
};

export const lifestyle_category_screen = {
	eventName: EVENT.VIEW_SCREEN,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "discovery",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "category",
		},
		{
			key: PARAM.KEY.CONTENT_CATEGORY,
			value: "lifestyle",
		},
	],
};

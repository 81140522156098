import {combineReducers} from "redux";
import {
  itemsReducer,
  recommendationsReducer,
  filteredMenuItems,
  filteredReducer,
  filteredSectionsReducer,
  subscriptionsReducer,
  categoriesReducer,
  profileReducer,
  networkReducer,
  appStateReducer,
  languageReducer,
  searchReducer,
} from ".";
import trackerReducer from "../../../analytics/tracker.reducer";
import authenticationReducer from "./authentication.reducer";

const rootReducer = combineReducers({
  recommendations: recommendationsReducer,
  items: itemsReducer,
  filteredMenuItems: filteredMenuItems,
  filtered: filteredReducer,
  search: searchReducer,
  filteredSections: filteredSectionsReducer,
  subscriptions: subscriptionsReducer,
  tracker: trackerReducer,
  categories: categoriesReducer,
  profile: profileReducer,
  network: networkReducer,
  appState: appStateReducer,
  authentication: authenticationReducer,
  language: languageReducer,
});

export default rootReducer;

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ItemSelected } from "../../HOC";
// import { CRED } from "../../utils/network/auth0Util";
import {
  Page,
  TopNav,
  ConfirmSection,
  SubscriptionBanner,
  Service,
} from "../../shared/components";

import { CheckableButton } from "../../shared/components/CheckableButton";
import { useNavigate } from "react-router-dom";
import { PWA_ROUTES } from "../../constants/appConstants";
import { SubscriptionDetails } from "../../shared/components/SubscriptionDetails";

import { initialize } from "../../utils/redux/actions/network.actions";
import { profile as profileAction } from "../../utils/redux/actions";
import {
  clearSubscription,
  selectCharge,
  // buyCurrentItem,
  // saveConsent,
} from "../../utils/redux/actions/subscription.action";
import { StyledPaymentMethods } from "../../shared/components/PaymentMethods/PaymentMethods.styles";
import loader from "../../assets/images/loader.gif";
import styled from "styled-components";
import { CheckoutScreen as events } from "../../analytics/events";
import { useSearchParams } from "react-router-dom";
// import axios from "axios";
import { SELECTED_SOURCE } from "../../appConstants";
// import f from "../../utils/network/apiCalls";

const SUBSCRIPTION = "subscription";
// const PROCEED = "proceed";
// const CLIENT_ID = "u2xEQ8Edn5mR6FZkiwfucRe9eKIuCKkY";
// const TENANT = "https://mtngh-dev.mtn-dev.auth0.com";
// const AUTH0_AUTHORIZATION_PATH = "/authorize";
// const AUDIENCE = "https://mtnplay.mtn.com";

const PaymentsScreen = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { network, profile } = useSelector((state) => state);
  const [searchParams] = useSearchParams();

  // const [subscriptionRenew] = useState(false);
  const [paymentMethodIndex, setPaymentMethodIndex] = useState(0);
  const [loading, setLoading] = useState(true);
  const [pMethod, setPmethod] = useState(false);

  const [pOtionNoMomo, setOptionNoMomo] = useState([]);

  // const {user, getAccessTokenSilently, getAccessTokenWithPopup, isAuthenticated, loginWithPopup} = useAuth0();

  const {
    selectedItem,
    selectedCharge,
    paymentMethods,
    paymentStatusInfo,
    error,
  } = useSelector((state) => state?.subscriptions);

  const service = selectedItem?.service;
  // useEffect(() => {
  //   dispatch({
  //     type: "ANALYTICS/TRACK_EVENT",
  //     event: events.payment_view_screen,
  //   });
  // }, []);

  useEffect(() => {
    if (paymentMethods?.length > 0) {
      let arr = paymentMethods.filter((e) => e?.balanceType !== "Momo");
      setOptionNoMomo(arr);
      setPmethod(true);
    }

    setLoading(false);
  }, [paymentMethods]);

  useEffect(() => {
    if (error && error != "") {
      dispatch(clearSubscription());
      navigate(PWA_ROUTES.GENERIC_ERROR);
    }
  }, [error]);

  useEffect(() => {
    if (paymentStatusInfo == null) return;

    if (paymentStatusInfo?.Message?.statusCode !== "") {
      navigate(PWA_ROUTES.TRANSACTION_RES);
    } else {
      dispatch(clearSubscription());
      navigate(PWA_ROUTES.GENERIC_ERROR);
    }
  }, [paymentStatusInfo]);

  useEffect(() => {
    dispatch({
      type: "ANALYTICS/TRACK_EVENT",
      event: events.payment_view_screen,
    });

    if (paymentMethods?.length > 0 && searchParams.get("code")) {
      const retrievedState = searchParams.get("state");
      if (retrievedState) {
        const parsedProfile = JSON.parse(retrievedState);

        dispatch(initialize(parsedProfile));
        dispatch(profileAction.updateProfile(parsedProfile));
        Authorize(searchParams.get("code"), parsedProfile);
      }
    }

    if (paymentMethods?.length > 0 && searchParams.get("error")) {
      const retrievedState = searchParams.get("state");
      if (retrievedState) {
        const parsedProfile = JSON.parse(retrievedState);
        dispatch(initialize(parsedProfile));
        dispatch(profileAction.updateProfile(parsedProfile));
        navigate(PWA_ROUTES.CONTENT_INFO);
      }
    }
    // dispatch(subscriptions.fetchPaymentMethods(network?.call));
  }, []);

  // const confirm = (accessToken, parsedProfile) => {
  //   setLoading(true);
  //   if (network?.call?.headers?.region) {
  //     const apiCall = new ApiCall(network?.call?.headers);
  //     dispatch(
  //       buyCurrentItem(apiCall, {
  //         ...selectedItem,
  //         subscriptionRenew,
  //         accessToken: accessToken,
  //         msisdn: parsedProfile.msisdn,
  //       }),
  //     );
  //   }


  //   dispatch({
  //     type: "ANALYTICS/TRACK_EVENT",
  //     event: events.confirm_purchase_tap(
  //       selectedItem?.itemType,
  //       selectedItem?.title,
  //       selectedItem?.subscriptionType,
  //       selectedCharge?.price,
  //       selectedItem?.vendorId,
  //     ),
  //   });
  // };

  // const handleChange = (e) => {
  // 	let isChecked = e.target.checked;
  // 	setSubscriptionRenew(isChecked);
  // };

	const Authorize = () => {
    navigate(PWA_ROUTES.CONSENT);
    //dispatch(saveConsent({}));
    // confirm(tokenResponse?.data?.access_token, parsedProfile);

    // var requiredScopes = "";
    // const mTitle = `${selectedItem?.service?.name || selectedItem?.name}`;
    // console.log("mmtitle", mTitle);
    // if (mTitle.includes("Trayton")) {
    //   requiredScopes = "subscribe:trayton";
    // }
    // if (mTitle.includes("Documoby")) {
    //   requiredScopes = "subscribe:documoby"; //✅
    // }
    // if (mTitle.includes("Mobile Icon")) {
    //   requiredScopes = "subscribe:mobile_icon";
    // }
    // if (mTitle.includes("MobileIcon")) {
    //   requiredScopes = "subscribe:mobile_icon";
    // }
    // if (mTitle.includes("Kunim Global")) {
    //   requiredScopes = "subscribe:kunim_global";
    // }
    // if (mTitle.includes("Starfish")) {
    //   requiredScopes = "subscribe:starfish";
    // }
    // if (mTitle.includes("U2opia")) {
    //   requiredScopes = "subscribe:u2opia";
    // }
    // if (mTitle.includes("Challenges Arena")) {
    //   requiredScopes = "subscribe:challenges_arena"; //✅
    // }
    // if (mTitle.includes("Docubay")) {
    //   requiredScopes = "subscribe:u2opia";
    // }
    // if (mTitle.includes("Reycreo")) {
    //   requiredScopes = "subscribe:reycreo"; //✅
    // }
    // if (mTitle.includes("233Comic")) {
    //   requiredScopes = "subscribe:233comic"; //✅
    // }
    // if (mTitle.includes("Playzone")) {
    //   requiredScopes = "subscribe:playzone"; //✅
    // }

    // let postObj = {
    //   grant_type: "authorization_code",
    //   redirect_uri: `${window.origin}`, //    ?code=gdrtcrt7g7
    //   client_id: CRED.CLIENT.ID,
    //   client_secret: CRED.CLIENT.SECRET,
    //   audience: CRED.CLIENT.AUDIENCE,
    //   //audience: "https://mtnplay.mtn.com",
    //   scope: `${requiredScopes}`,
    //   code: code,
    //   prompt: "consent",
    // };

    // axios
    //   .post(CRED.CLIENT.TOKEN_ENDPOINT, postObj)
    //   .then((tokenResponse) => {
    //     console.log(
    //       "@tokenResponse?.data?.access_token ",
    //       tokenResponse?.data?.access_token,
    //     );

    //     confirm(tokenResponse?.data?.access_token, parsedProfile);
    //   })
    //   .catch((exception) => {
    //     console.log("@exception: ", exception);
    //     const urlBuildup = `${CRED.CLIENT.AUTHORISATION_ENDPOINT}?audience=${
    //       CRED.CLIENT.AUDIENCE
    //     }&scope=${requiredScopes}&response_type=code&client_id=${
    //       CRED.CLIENT.ID
    //     }&redirect_uri=${window.origin}${
    //       PWA_ROUTES.PAYMENTS
    //     }&state=${JSON.stringify(profile.data)}`;
    //     console.info("@urlBuildup: ", urlBuildup);
    //     window.location.replace(urlBuildup);
    //     dispatch({
    //       type: "ANALYTICS/TRACK_EVENT",
    //       event: events.consent_request_tap,
    //     });
    //   });
  };

  // const renewOption = () => {
  // 	return (
  // 		<div className="method_second">
  // 			<div onClick={() => {console.log("hitt@@");}}>
  // 				<label htmlFor="html">Automatically renew my subscription</label>
  // 			</div>
  // 			<label className="switch">
  // 				<input onChange={e => handleChange(e)} checked={subscriptionRenew} type="checkbox"/>
  // 				<span className="slider round"></span>
  // 			</label>
  // 		</div>
  // 	);
  // };

  const ModalTextComponent = () => {
    return (
      <>
        <div className="modal_text">
          {selectedItem?.subscriptionType == "once off" ? (
            <p>{`R${selectedItem?.price} will be deducted from your Airtime.`}</p>
          ) : (
            <p>{`You will be charged ${selectedCharge?.currency} ${selectedCharge?.price} per ${selectedCharge?.value} from your airtime.`}</p>
          )}
          <p>{"You can cancel at any time from your Profile."}</p>
          <div style={{ marginTop: 45 }}>
            <p>
              By subscribing, you agree to the{" "}
              <span
                onClick={() => {
                  navigate(PWA_ROUTES.TERMS_AND_CONDITIONS);
                }}
                style={{ color: "#FFCB05" }}>
                {" "}
                Terms & Conditions
              </span>{" "}
              and{" "}
              <span
                onClick={() => {
                  navigate(PWA_ROUTES.PRIVACY_NOTICE);
                }}
                style={{ color: "#FFCB05" }}>
                Privacy Notices
              </span>{" "}
              of the service.
            </p>
          </div>
        </div>
      </>
    );
  };

  return (
    <ItemSelected restrictFree={true}>
      <Page isBottomBar={false}>
        <TopNav
          title={
            selectedItem?.selectedSource === SELECTED_SOURCE.CONTENT
              ? selectedItem?.title
              : selectedItem?.name
          }
          enableSearch={false}
          showCloseIcon={true}
        />
        {selectedItem?.selectedSource === SELECTED_SOURCE.CONTENT ? (
          <>
            <SubscriptionBanner
              paymentStyle={true}
              imageUrl={selectedItem?.imageUrl}
            />
            <div
              style={{ display: "flex", alignSelf: "center", marginTop: -10 }}>
              <Service
                id={service?.id}
                logo={service?.logo}
                name={service?.name}
                description={service?.description}
              />
            </div>
          </>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              paddingTop: 15,
              paddingBottom: 15,
              paddingRight: 30,
              paddingLeft: 30,
            }}>
            <div
              style={{
                backgroundImage: `url(${selectedItem?.logo})`,
                height: 112,
                width: 112,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
              }}
            />
            <div>
              <p style={{ opacity: 0.6, marginTop: 10 }}>
                {selectedItem?.description}
              </p>
            </div>
          </div>
        )}

        <div>
          {selectedItem?.subscribed !== "Pending" && (
            <div style={{ paddingLeft: 25, paddingRight: 25 }}>
              <SubscriptionDetails />
            </div>
          )}
        </div>

        {pMethod && (
          <StyledPaymentMethods>
            <OptionDescr>Selected payment option</OptionDescr>
            {}
            {pOtionNoMomo?.length ? (
              pOtionNoMomo?.map((item, index) => {
                return (
                  <CheckableButton
                    isActive={item == "Airtime"}
                    selected={paymentMethodIndex == index}
                    body={item?.balanceType}
                    clickHandler={() => {
                      dispatch(selectCharge(item));
                      setPaymentMethodIndex(index);
                    }}
                    key={item?.balanceDetail?.activeValue}
                  />
                );
              })
            ) : (
              <Loader>
                <img src={loader} alt="" width="60" height="60" />
              </Loader>
            )}
            {/* {
            		selectedCharge?.value !== "once-off" &&
                    <div className="actions_second">
                    	{renewOption()}
                    </div>
            	}                        */}
            <ModalTextComponent />

            <ConfirmSection
              confirmHandler={() => {
                Authorize();
              }}
              buttonCaption={
                selectedItem?.content?.subscriptionType === SUBSCRIPTION
                  ? "subscribe"
                  : "confirm subscription"
              }
              loading={loading}
            />
          </StyledPaymentMethods>
        )}
      </Page>
    </ItemSelected>
  );
};

const OptionDescr = styled.p`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin-top: 0px;
  margin-bottom: 12px;
`;

const Loader = styled.div`
  display: flex;
  padding: 14px;
  justify-content: center;
`;

export default PaymentsScreen;

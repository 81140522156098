// import React from "react";
import { Route, Routes } from "react-router";
import { PWA_ROUTES } from "../../constants/appConstants";
import { BalanceInfo, TopUpMethods, Border, TopUpVoucher, Page, TopNav } from "../../shared/components";

const TopUpScreen = () => {
	return (
		<Page isBottomBar={true}>
			 <TopNav title={"Topup Options"} />
			<BalanceInfo />
			<Border />
			<Routes>
				<Route exact path={PWA_ROUTES.TOPUP_METHOD} element={<TopUpMethods />}/>
				<Route exact path={PWA_ROUTES.TOPUP_VOUCHER} element={<TopUpVoucher />} />
				<Route exact path={"/*"} element={<TopUpMethods />}/>
			</Routes>
		</Page>
	);
};

export default TopUpScreen;

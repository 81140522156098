import {EVENT, PARAM} from "./helpers";

export const manage_screen = {
	eventName: EVENT.VIEW_SCREEN,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "discovery",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "manage",
		},
	],
};

export const logout = {
	eventName: EVENT.TAP,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "settings",
		},
		{
			key: PARAM.KEY.TAP_TARGET,
			value: "logout",
		},
	],
};

export const zigiClick = {
	eventName: EVENT.TAP,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "settings",
		},
		{
			key: PARAM.KEY.TAP_TARGET,
			value: "zigi",
		},
	],
};



import {useState, useEffect} from "react";
import { useNavigate } from "react-router-dom";
import  Button  from "../../components/Button/Button";
import { PWA_ROUTES } from "../../../constants/appConstants";
import { StyledSubscriptionSuccessInfo } from "./SubscriptionSuccessInfo.styles";
import {useSelector, useDispatch} from "react-redux";
import styled from "styled-components";
import {clearSubscription} from "../../../utils/redux/actions/subscription.action";

const SubscriptionSuccessInfo = ({info, info1="", info2=""}) => {
	const dispatch = useDispatch();
	// const {redirectUrlObj} = useSelector((state) => state?.items);

	const {
		selectedItem,
		paymentStatusInfo,
	  } = useSelector((state) => state?.subscriptions);

	  const {
		error,
		fetchRedirectError
	  } = useSelector((state) => state?.items);

	const navigate = useNavigate();
	const [redirectUrl, setRedirectUrl] = useState(undefined);
	const [redirectUrlPass, setRedirectUrlPass] = useState(undefined);
	const {profile} = useSelector((state) => state);


	// const {subscriptions, network} = useSelector((state) => state);

	useEffect(() => {
		if((error && error!="")) {

		  dispatch(clearSubscription());
		  navigate(PWA_ROUTES.GENERIC_ERROR);
		}
	}, [error]);

	useEffect(() => {
		if(fetchRedirectError==true) {
		  dispatch(clearSubscription());
		  navigate(PWA_ROUTES.GENERIC_ERROR);
		}
	}, [fetchRedirectError]);

	useEffect(() => {
		// setRedirectUrl(undefined)
		if(!selectedItem?.internalPlay  && selectedItem?.subscribed !== "0000") {
      //dispatch(getRedirect(network?.call, selectedItem?.billingTypes ? selectedItem?.billingTypes[0] : selectedItem?.billingType ));
      // let a = new URL(selectedItem?.url);
      // a.searchParams.set("msisdn", profile?.data?.msisdn);
      // setRedirectUrlPass(a.toString() );

      const _url = selectedItem?.url || selectedItem?.homeUrl;
      if (_url?.includes("?")) {
        setRedirectUrlPass(_url + "&msisdn=" + profile?.data?.msisdn);
      } else {
        let a = new URL(_url);
        a.searchParams.set("msisdn", profile?.data?.msisdn);
        setRedirectUrlPass(a.toString());
      }
    }

	  }, []);

	  useEffect(()=>{
		if(redirectUrlPass !== null) {
		   setRedirectUrl(redirectUrlPass);
		}
		
	  },[redirectUrlPass]);
	
	return (
    <StyledSubscriptionSuccessInfo>
      {paymentStatusInfo?.Message?.statusCode === "0000" && (
        <div>
          <div className="status">{info}</div>
          <div className="info">{info1}</div>
          <div
            style={{ color: "#FFCB05", fontStyle: "italic" }}
            className="info">
            {info2}
          </div>

          <div className="button-wrapper">
            <Button
              clickHandler={() => {
                navigate(PWA_ROUTES.HOME);
              }}
              className="button"
              caption="continue browsing"
            />
          </div>
        </div>
      )}

      {paymentStatusInfo?.Message.statusCode === "2084" && (
        <div>
          <div className="status">You are already subscribed</div>
          <div className="info">
            {" "}
            Click the button below to enjoy your content.{" "}
          </div>

          <div style={{ display: "flex" }}>
            {redirectUrl !== undefined &&
              !selectedItem?.internalPlay &&
              redirectUrlPass !== undefined && (
                <div
                  style={{
                    textDecoration: "none",
                    color: "#ffff",
                    width: "100%",
                    marginLeft: "24px",
                    marginRight: "24px",
                  }}>
                  <a
                    style={{
                      borderRadius: 40,
                      height: 40,
                      backgroundColor: "#FFCB05",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      color: "#000",
                    }}
                    href={redirectUrlPass}
                    target="_blank"
                    rel="noopener noreferrer">
                    PLAY NOW
                  </a>
                </div>
              )}

            {redirectUrlPass == undefined && !selectedItem?.internalPlay && (
              <div
                style={{
                  textDecoration: "none",
                  color: "#ffff",
                  width: "100%",
                  marginLeft: "24px",
                  marginRight: "24px",
                }}>
                <a
                  style={{
                    borderRadius: 40,
                    height: 40,
                    backgroundColor: "grey",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    color: "#000",
                  }}
                  href={redirectUrlPass}
                  target="_blank"
                  rel="noopener noreferrer">
                  {" "}
                  <StyledButtonLoading></StyledButtonLoading>
                </a>
              </div>
            )}
          </div>
          <div style={{
            textDecoration: "none",
            color: "#ffff",
            marginLeft: "24px",
            marginRight: "24px",
            marginTop: "10px",
          }}>
            <Button
              clickHandler={() => {
                navigate(PWA_ROUTES.HOME);
              }}
              className="button"
              caption="continue browsing"
            />
          </div>
        </div>
      )}

      {paymentStatusInfo?.Message.statusCode === "2032" && (
        <div>
          <div className="status">Insufficient balance</div>
          <div className="info"> Click the button below to return. </div>

          <div className="button-wrapper">
            <Button
              clickHandler={() => {
                navigate(PWA_ROUTES.HOME);
              }}
              className="button"
              caption="continue browsing"
            />
          </div>
        </div>
      )}
      {paymentStatusInfo?.Message.statusCode === "1012" && (
        <div>
          <div className="status">Offers not found</div>
          <div className="info"> We did not find any offers for this subscription. </div>

          <div className="button-wrapper">
            <Button
              clickHandler={() => {
                navigate(PWA_ROUTES.HOME);
              }}
              className="button"
              caption="continue browsing"
            />
          </div>
        </div>
      )}
    </StyledSubscriptionSuccessInfo>
  );
};

const StyledButtonLoading = styled.div`
	width: 30px;
	height: 30px;
	border: 6px solid ${({ primary }) => (primary ? "#fff" : "#333")};
	border-bottom-color: transparent;
	border-radius: 45px;
	display: flex;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
	@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
	}
`;

export default SubscriptionSuccessInfo;
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {Faqs, Page, TopNav} from "../../shared/components";

import {HelpScreen as events} from "../../analytics/events";

const HelpScreen = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch({
			type: "ANALYTICS/TRACK_EVENT",
			event: events.help_topics_list_screen,
		});
	}, []);

	const faq_events = {
		dispatch: dispatch,
		ClickFAQ: {type: "ANALYTICS/TRACK_EVENT", event: events.expand_faq_item},
	};

	return (
    <Page isBottomBar={false}>
      <TopNav title={"Help"} enableSearch={false} centerTitle={true} />
      <Faqs events={faq_events} />
    </Page>
  );
};

export default HelpScreen;

import {useEffect} from "react";
import {Page, TabbedList, TopNav} from "../../shared/components";
import {useDispatch, useSelector} from "react-redux";
import {fetchUserPurchases} from "../../utils/redux/actions/subscription.action";
import {PurchaseScreen as events} from "../../analytics/events";
// import DirectionProvider, { DIRECTIONS } from "react-with-direction/dist/DirectionProvider";

const PurchaseScreen = () => {
	const dispatch = useDispatch();
	const {subscriptions, network,language} = useSelector((state) => state);
  
	const allPurchases = subscriptions?.purchases;

	useEffect(() => {
		dispatch({
			type: "ANALYTICS/TRACK_EVENT",
			event: events.purchase_management_screen,
		});

		dispatch({
			type: "ANALYTICS/TRACK_EVENT",
			event: events.active_tab_tap(
				"active"
			),
		  });

	}, []);

	useEffect(() => {
		dispatch(fetchUserPurchases(network?.call));
	}, [network]);

	const sort_events = {
		dispatch: dispatch,
		ViewModal: {
			type: "ANALYTICS/TRACK_EVENT",
			event: events.sort_option_modal_view,
		},
		CancelModal: {
			type: "ANALYTICS/TRACK_EVENT",
			event: events.sort_cancel_tap,
		},
		"a-z": {type: "ANALYTICS/TRACK_EVENT", event: events.sort_a_z_tap},
		date: {type: "ANALYTICS/TRACK_EVENT", event: events.sort_date_tap},
		price: {type: "ANALYTICS/TRACK_EVENT", event: events.sort_price_tap},
		title: {type: "ANALYTICS/TRACK_EVENT", event: events.sort_title_tap},
	};


	const tab_events = {
		dispatch: dispatch,
		Active: {type: "ANALYTICS/TRACK_EVENT", event: events.active_tab_tap},
		Inactive: {type: "ANALYTICS/TRACK_EVENT", event: events.inactive_tab_tap},
		Pending: {type: "ANALYTICS/TRACK_EVENT", event: events.pending_tab_tap},
	};


	return (
		<>
			{/* <DirectionProvider direction={language?.chosenLanguage === "arabic" ? DIRECTIONS.RTL:DIRECTIONS.LTR}> */}

				<Page isBottomBar={true}>
					<TopNav title={language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections_content?.purchases?.screen_title} enableSearch={false}/>
					<TabbedList
						type="purchases"
						items={allPurchases === "Active"? language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections_content?.purchases?.active : allPurchases === "InActive"? language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections_content?.purchases?.inactive: language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections_content?.purchases?.pending}
						sort_events={sort_events}
						tab_events={tab_events}
					/>
				</Page>
			{/* </DirectionProvider> */}
		</>
	);
};

export default PurchaseScreen;

import {useState, useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import { Button } from "../../shared/components/Button";
import loader from "../../assets/images/MTNPlayLogo.png";
import ghana from "../../assets/images/ghana.png";
import {requestOTP} from "../../utils/redux/actions/authentication.actions";
import { PWA_ROUTES } from "../../constants/appConstants";
import { useNavigate } from "react-router";
import { TermsOfServiceStyle } from "./LoginScreen.style";
import {AuthenticationScreen as events} from "../../analytics/events";

const Login = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const {network, authentication} = useSelector((state) => state);
	const [msisdn, setMsisdn] = useState(undefined);
	const [emptyField, setEmptyField] = useState(false);
	const [errorInfo, setErrorInfo] = useState(false);
	const [loading, setLoading] = useState(false);

	useEffect(()=>{
		dispatch({
			type: "ANALYTICS/TRACK_EVENT",
			event: events.login_screen,
		});
		navigate(PWA_ROUTES.ROOT,{replace:true});
	}, []);

	useEffect(()=>{
		setLoading(authentication.loading);
		if(authentication.loading===false && authentication.msg=="done"){
			dispatch({
				type: "ANALYTICS/TRACK_EVENT",
				event: events.otp_successful,
			});
			navigate(PWA_ROUTES.OTP, {state: {
				msisdn: "233"+msisdn
			}});
			return;
		}
        
		if(authentication.error===true){
			navigate(PWA_ROUTES.GENERIC_ERROR);
			dispatch({
				type: "ANALYTICS/TRACK_EVENT",
				event: events.otp_fail,
			});
		}
        
	}, [authentication.loading]);
	
	const handleChangeMsisdn = (event) =>{
		setEmptyField(false);
		setErrorInfo(false);
		event.preventDefault();
		setMsisdn(event?.target?.value);
	};

	const onHandleSubmit = () =>{
		if(msisdn === undefined || msisdn === "" ) {
			setEmptyField(true);
		} else if(msisdn.length<9 || msisdn.length>9){
			setErrorInfo(true);
		}else {
			dispatch(requestOTP(network?.call, "233"+msisdn));
			dispatch({
				type: "ANALYTICS/TRACK_EVENT",
				event: events.otp_request,
			});
		}
	};

	return (
		<div style={{justifyContent: "center", alignItems: "center"}}>
			<div style={{ marginTop:40}}>
				<p style={{color:"white", fontWeight: "bold", fontSize: 22, textAlign:"center"}}>Sign in to Play</p>
				<span style={{display:"flex", justifyContent:"center", marginTop:30}}>
					<img src={loader} alt="" width="120" height="120" />
				</span>              
				<div style={{marginTop:25}}>
					<p style={{color:"white", padding:20, fontWeight: "bold", fontSize: 18, textAlign:"center"}}>Please enter your cellphone number to continue</p>
					<span style={{ paddingLeft: 26, color:"#FFFFFF", fontSize:"12px"}}>Phone number</span>
					<div style={{display:"flex", paddingRight: 26, paddingLeft: 26, marginTop:4}}>
                        
						<div style={{display:"flex", alignItems:"center", paddingLeft: 5, borderRadius:"4px 0px 0px 4px", border:"1px solid #fff"}}>
							<img src={ghana} width="30" height="25"  />
							<input placeholder="+233" style={{ paddingLeft:5, borderRadius:"4px 0px 0px 4px", height: 40, color:"#BDBDBD", border:"1px solid transparent", backgroundColor: "transparent"}}  size="5" type="text"  />
						</div>
						<span style={{width:"100%"}}>
							<input key={"msisdn"} placeholder={""} value={msisdn?`${msisdn}`:""} onChange={handleChangeMsisdn} style={{borderRadius:"0px 4px 4px 0px", textOverflow:"ellipsis", height: 42, color:"#BDBDBD", border:"1px solid #fff", backgroundColor: "transparent", paddingLeft: "10px", paddingRight: "10px", width:"100%"}} size="22" type="number"  />
						</span>
					</div>
                    
					<TermsOfServiceStyle>
						<span>By clicking continue, you have read, understood and agree to be bound by the service's <span className="terms" onClick={()=>{navigate(PWA_ROUTES.TERMS_AND_CONDITIONS);}}>terms & conditions</span> and <span className="terms" onClick={()=>{navigate(PWA_ROUTES.TERMS_AND_CONDITIONS);}}>privacy policy</span>.</span>
                    
						<div className="action-buttons">
							<Button isOutline={false} loading={loading} clickHandler={onHandleSubmit} caption="CONTINUE" />
							<div style={{marginTop:28}}/>
							<Button isOutline={true} clickHandler={() => {navigate(PWA_ROUTES.HOME);}} caption="BACK" />
						</div>

                     
					</TermsOfServiceStyle>

					{emptyField === true || errorInfo === true ?
						<div style={{marginTop:"5%", paddingLeft: 26, position:"absolute"}}>
							<p style={{color:"white", fontSize: 10}}> <span style = {{color:"#DC6428"}}>{"Please enter a valid phone number."}</span></p>
						</div> : <></>
					}
				</div>
			</div>

		</div>
	);
};

export default Login;
import {motion} from "framer-motion";
import {useEffect, useState} from "react";
import {Border} from "..";
import {StyledFaqs} from "./Faqs.styles";

const dummyItems = [
	{
		topic: "Help FAQ's",
		questions: [
			{
				value: "How do I login?",
				showAnswer: false,
				answer:
          "Go to the home page and click the login button. You may be prompted to input your mobile number and a pin that will be sent to your phone by MTN Play.",
			},
			{
				value: "How do I pay for content?", 
				showAnswer: false,
				answer: "When you subscribe to content, you will be able to select your payment method as either airtime payment or MoMo"},
			{
				value: "How do I Subscribe?",
				showAnswer: false,
				answer: "Click on subscribe button from the content that you would want to subscribe ",
			},
			{
				value: "How do I watch content?",
				showAnswer: false,
				answer: "Depending on the content you want to watch, you may be redirected to another page to watch, or you may be sent an SMS with instructions to access the content or just click the content on MTN Play to watch",
			},
			{
				value: "Will I be charged for each item?",
				showAnswer: false,
				answer: "There is an advice of charge on each item where price and validity period is shown. Some items are free, some attract a price for various valid periods and it will be displayed as such",
			},
			{
				value: "How do I confirm a subscription?",
				showAnswer: false,
				answer: "Subscription is confirmed through two steps confirmation. Once you have clicked to subscribe, selected your subscription period and payment method, you will be prompted to confirm your subscription",
			},
			{
				value: "How do I unsubscribe from content?",
				showAnswer: false,
				answer: "Visit the 'manage' section of the app. Select subscriptions to manage any of your subscriptions"
			},
			{
				value: "How do I send feedback?",
				showAnswer: false,
				answer: "Visit the 'manage' section of the app. Select 'Help' > Feedback option"
			},
			{
				value: "How do I renew my subscription?",
				showAnswer: false,
				answer: "You may visit 'MANAGE' section, select the content  you would want to renew subscriptions for. For better customer experience, when you first time subscribe to content, make sure you select autonewal and MTN Play will automatically renew your subscribe everytime at the renewal time"
			},
			{
				value: "How to I view content I've suscribed to?",
				showAnswer: false,
				answer: "Click 'MY Play' at the bottom of navigation bar and you will be able to view a list of content that you have subscribed to"
			},
			{
				value: "What are content providers?",
				showAnswer: false,
				answer: "There are various content providers that MTN provide the content through MTN Play. Most of the content is owned and maintained by these content providers"
			},
			{
				value: "What is MTN Play?",
				showAnswer: false,
				answer: "MTN Play is a digital media service that provides various types of content"
			},
			{
				value: "How do I create a shortcut on my device?",
				showAnswer: false,
				answer: "If you are first time opening MTN Play, you will be prompted to click and create a shortcut on your mobile phone. Alternatively click settings option of your browser and you will find an option to create a shortcut. On iOS select the share button and then select 'Add to Home Screen'. On Android tap on the three dots and select install 'MTN Play PWA'"
			},
			{
				value: "If I didn’t get my MTN Play content, where can I get assistance?",
				showAnswer: false,
				answer: "Please call  100 from your MTN number"
			},
			{
				value: "Will I be charged airtime / data to view content?",
				showAnswer: false,
				answer: "Yes, data/airtime will be consumed when streaming certain services and content"
			},
			{
				value: "What are the terms and Conditions?",
				showAnswer: false,
				answer: "Please visit the 'manage' section of the app. Select Terms & Conditions"
			}
		],
	},
	// {
	//   topic: 'Subscriptions',
	// },
	// {
	//   topic: 'Purchases',
	//   questions: [
	//     {
	//       value: 'What is the purchase?',
	//       showAnswer: false,
	//       answer: 'Purchase is when you pay and have endless access',
	//     },
	//     {
	//       value: 'How are purchases handled?',
	//       showAnswer: false,
	//       answer: 'see refer terms.',
	//     },
	//     {
	//       value: 'how long can I see my purchases?',
	//       showAnswer: false,
	//       answer: 'answer is at the backend.',
	//     },
	//   ],
	// },
];
const Faqs = ({events = {}}) => {
	const [items, setItems] = useState(dummyItems);
	const [selectedItem, setSelectedItem] = useState(false);
	const [defaultItemTracked, setDefaultItemTracked] = useState(false);

	useEffect(() => {
		if (defaultItemTracked) {
			if ("dispatch" in events) {
				if (
					"type" in events.ClickFAQ &&
          "event" in events.ClickFAQ &&
          Object.keys(events.ClickFAQ.event(selectedItem)).length > 0
				) {
					let _event = {
						event: events.ClickFAQ.event(selectedItem),
						type: events.ClickFAQ.type,
					};
					events.dispatch(_event);
				}
			}
		} else {
			setDefaultItemTracked(true);
		}
	}, [selectedItem]);

	return (
		<StyledFaqs>
			{items?.map((v) => (
				<div className="topic" key={v?.topic}>
					<div className="topic-name">{v?.topic}</div>
					<div className="questions">
						{v.questions?.map((q) => (
							<div
								onClick={() => {
									q.showAnswer = !q.showAnswer;
									setSelectedItem(q.value);
									const newItems = [...items];
									setItems(newItems);
								}}
								key={q?.value}//555741621
								className="question">
								<div className="first-row">
									<div className="value">{q?.value}</div>
									<motion.div
										className={`sort-icon-faq icon ${q?.showAnswer ? "show" : "normal"}`}></motion.div>
								</div>
								{q?.showAnswer ? (
									<motion.div
										initial={{opacity: 0}}
										animate={{opacity: 1}}
										exit={{opacity: 0}}
										className="answer">
										{q.answer}
									</motion.div>
								) : (
									<></>
								)}
								<Border />
							</div>
						))}
					</div>
				</div>
			))}
		</StyledFaqs>
	);
};

export default Faqs;

import  {useEffect} from "react";
import {useDispatch} from "react-redux";
import {Page, TopNav} from "../../shared/components";
import {Profile} from "../../shared/components/Profile";
import {ProfileScreen as events} from "../../analytics/events";
import {updateProfile} from "../../utils/redux/actions/profile.actions";

const ProfileScreen = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch({
			type: "ANALYTICS/TRACK_EVENT",
			event: events.profile_screen,
		});
	}, []);

	const doUpdateProfile = (userData) =>{
		dispatch(updateProfile(userData));
	};

	return (
    <Page isBottomBar={false}>
      <TopNav title="my profile" centerTitle={true} enableSearch={false} />
      <Profile doUpdate={doUpdateProfile} />
    </Page>
  );
};

export default ProfileScreen;

import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {TopNav, Page} from "../../shared/components";
import {TermsAndConditionsScreen as events} from "../../analytics/events";
// import { uri } from "../../constants/appConstants"; 


const TermsAndConditionsScreen = () => {
	const dispatch = useDispatch();

	const {profile} = useSelector((state) => state);
	const [html, setHTML] = useState({__html: ""});

	useEffect(async () => {
		async function createMarkup() {
			let response;
			response = await fetch("/terms-and-conditions/", {
        headers: {
          msisdn: `${profile?.data?.msisdn}`.replace("+", ""),
        },
      });

			const backendHtmlString = await response.text();
			return {__html: backendHtmlString};
		}
		return createMarkup().then(result => setHTML(result));
	}, []);

	useEffect(() => {
		dispatch({
			type: "ANALYTICS/TRACK_EVENT",
			event: events.terms_and_conditions_screen,
		});
	}, []);

	return (
    <Page isBottomBar={false}>
      <TopNav
        title="Terms and Conditions"
        enableSearch={false}
        showCloseIcon={false}
        centerTitle={true}
      />
      <div style={{ padding: 20 }} dangerouslySetInnerHTML={html} />
    </Page>
  );
};

export default TermsAndConditionsScreen;
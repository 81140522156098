import { language } from "../types";





export const setCountry = (country) => async(dispatch) =>{
	dispatch({
		type: language.SET_COUNTRY_SUCCESS,
		payload: country
	});
};
import { useMemo, useRef } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

const BANNERS = [
  {
    img: "/images/banners/crbt.jpeg",
    url: "https://callertunez.mtn.com.gh/crbt-web-portal/home?lang=en",
  },
  {
    img: "/images/banners/gameloft_banner_640x400.png",
    url: "https://gameworld.mtn.com.gh/landing/landing.php?adid=436684&product=4164",
  },
];

const BannerCarousel = () => {
  const carouselRef = useRef(null);
  const items = useMemo(() => [...BANNERS]);

  const onClick = (i) => {
    window.open(items[i].url);
  };

  return (
    <Carousel
      ref={carouselRef}
      swipeable={false}
      showArrows={false}
      showIndicators={false}
      showStatus={false}
      showThumbs={false}
      onClickItem={onClick}
      autoPlay
      infiniteLoop
      useKeyboardArrows={false}
      interval={6000}>
      {items.map((item, i) => (
        <div
          key={i}
          style={{
            height: 220,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundImage: `url(${item.img})`,
          }}></div>
      ))}
    </Carousel>
  );
};

export default BannerCarousel;

import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { LandingCategory, Page, TopNav } from "../../shared/components";
import { HomeScreen as events } from "../../analytics/events";
import { appState as appStateAction } from "../../utils/redux/actions";
import {
  fetchBanners,
  fetchItems,
  // fetchAdInventory,
  // fetchAdInventoryMbks,
  fetchChannels,
  fetchPlayInstance,
  // fetchAllContentByProvider
} from "../../utils/redux/actions/items.actions";
import { fetchItemsFilterAllByCategory } from "../../utils/redux/actions/filtered.actions";
import { clearSubscription } from "../../utils/redux/actions/subscription.action";
import { clearRedirect } from "../../utils/redux/actions/items.actions";
import close from "../../assets/images/close.png";
import loaderskel from "../../assets/images/loader-skeleton.png";
import { StyledBottomPopup } from "../../shared/components/BottomBar/popup";
import { StyledIosPopup } from "../../shared/components/IosPopup/IosPopup.styles";
import styled from "styled-components";
import { CategoryMenu } from "../../shared/components/DeepMenu/CategoryMenu/";
import { useNavigate } from "react-router-dom";
import {
  PWA_ROUTES,
  RECOMMENDATIONS_HEADINGS,
} from "../../constants/appConstants";
 import { subscriptions } from "../../utils/redux/actions";
 //  import { selectItem } from "../../utils/redux/actions/subscription.action";
 import PureModal from "react-pure-modal";
 // import HelperFunctions from "../../utils/HelperFunctions";
 import { fetchRecommendationsItems } from "../../utils/redux/actions/recommendations.actions";
 import logo from "../../assets/images/MTNPlayLogo.png";
 import addHome from "../../assets/images/AddHome.svg";
 import { BannerCarousel } from "../../shared/components/BannerCarousel";
 // import { Ads } from "../../shared/components/Ads";
 import { ChannelCard } from "../../shared/components/ChannelCard";
 // import { useAuth0 } from "@auth0/auth0-react";
 // import {  ModalButtons, ModalText, Modal } from "../../shared/components";

 // import CoolTabs from '../../shared/components/react-cool-tabs';
 // import CoolTabs from "react-cool-tabs";
 // const helperFunction = new HelperFunctions();

 const width = window.innerWidth;
 const height = window.innerHeight;

 const HomeScreen = () => {
   const dispatch = useDispatch();
   const navigate = useNavigate();

   const {
     items,
     recommendations,
     network,
     appState,
     filteredMenuItems,
     language,
   } = useSelector((state) => state);

   const { menuState } = appState;
   const { onInstalledPWA } = appStateAction;

   // const [_filtered, set_filtered] = useState(filtered);

   const [query, setQuery] = useState("");

   // const [closeInstallPWA, setCloseInstallPWA] = useState(false);

   const [modalPending, setModalPending] = useState(false);
   // const [modalControl, setModalControl] = useState(false);
   // const { isAuthenticated } = useAuth0();

   // const platform  = "MacIntel";

   // const ads =
   //   {
   //     position: "videos",
   //     data: {
   //       enableOverlay: true,
   //       header:
   //         language?.chosenPack?.language?.COMPONENTS?.footer_banner?.header,
   //       body: language?.chosenPack?.language?.COMPONENTS?.footer_banner
   //         ?.description,
   //       actionButton:
   //         language?.chosenPack?.language?.COMPONENTS?.footer_banner
   //           ?.button_label,
   //       link:  "http:" + items?.advertInventory?.adResponse?.clickUrl,
   //     },
   //     imgUrl: "http:" + items?.advertInventory?.adResponse?.imgUrl,
   //   };

   // const adsMbks =
   // {
   //   position: "",
   //   data: {
   //     enableOverlay: true,
   //     header:
   //       language?.chosenPack?.language?.COMPONENTS?.footer_banner?.header,
   //     body: language?.chosenPack?.language?.COMPONENTS?.footer_banner
   //       ?.description,
   //     actionButton:
   //       language?.chosenPack?.language?.COMPONENTS?.footer_banner
   //         ?.button_label,
   //     link:  "http:" + items?.advertInventoryMbks?.adResponse?.clickUrl,
   //   },
   //   imgUrl: "http:" + items?.advertInventoryMbks?.adResponse?.imgUrl,
   // };

   const { profile } = useSelector((state) => state);
   const { selectedItem, pendingItem, error } = useSelector(
     (state) => state?.subscriptions,
   );


   // const emptyFilteredSections = filtered == null ? true : (Object.keys(filtered).length === 0);

   useEffect(() => {
     dispatch({
       type: "ANALYTICS/TRACK_EVENT",
       event: events.home_screen,
     });

     dispatch(clearRedirect());
     items?.items?.map((res) => {
       if (res.name === pendingItem?.itemType) {
         res?.data?.map((response) => {
           response?.billingTypes?.map((ress) => {
             if (ress.subscriptionId === pendingItem.subscriptionId) {
               if (response.subscribed === "Active") {
               } else if (response.subscribed === "Pending") {
                 if (localStorage.getItem("pending-state-closed") !== null) {
                   setModalPending(false);
                 } else {
                   setModalPending(true);
                 }
               } else if (response.subscribed === "Inactive") {
                 //setModalPending(false)
               }
             }
           });
         });
       }
     });
     getMobileOperatingSystem();
   }, []);

   useEffect(() => {
     if (error !== null) {
       dispatch(clearSubscription());
       navigate(PWA_ROUTES.GENERIC_ERROR);
     }
   }, [error]);

   useEffect(() => {
     items?.items?.map((res) => {
       if (res.name === pendingItem?.itemType) {
         res?.data?.map((response) => {
           response?.billingTypes?.map((ress) => {
             if (ress.subscriptionId === pendingItem.subscriptionId) {
               if (response.subscribed === "Active") {
               } else if (response.subscribed === "Pending") {
                 if (localStorage.getItem("pending-state-closed") !== null) {
                   setModalPending(false);
                 } else {
                   setModalPending(true);
                 }
               } else if (response.subscribed === "Inactive") {
                 //setModalPending(false)
               }
             }
           });
         });
       }
     });
   }, [pendingItem]);

   useEffect(() => {
     doSearch(query);
   }, [query]);

   useEffect(() => {
     if (
       network?.call !== null ||
       network?.call !== undefined ||
       network?.call !== ""
     ) {
       if (
         network?.call?.headers?.region !== null ||
         network?.call?.headers?.region !== undefined ||
         network?.call?.headers?.region !== ""
       ) {
         if (network?.call?.headers?.region) {
           dispatch(fetchChannels(network?.call));
           dispatch(clearSubscription());
           dispatch(fetchRecommendationsItems(network?.call));
           dispatch(fetchItems(network?.call));
           dispatch(fetchPlayInstance(network?.call));
           dispatch(fetchBanners(network?.call));
          //  dispatch(fetchAdInventory(network?.call, 1687));
          //  dispatch(fetchAdInventoryMbks(network?.call, 1688));
         }
       }
     }
   }, [network]);

   function getMobileOperatingSystem() {
     var userAgent = navigator.userAgent || navigator.vendor || window.opera;

     // Windows Phone must come first because its UA also contains "Android"
     if (/windows phone/i.test(userAgent)) {
       return "Windows Phone";
     }

     if (/android/i.test(userAgent)) {
       return "Android";
     }

     if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
       if (localStorage.getItem("user-closed") === null) {
       } else {
       }
       return "iOS";
     }

     return "unknown";
   }

   // const modalSwich = () =>{
   // 	setModal(false);
   // 	localStorage.setItem("user-closed", true);
   // };

   const switchPendingState = () => {
     setModalPending(false);
     localStorage.setItem("pending-state-closed", true);
   };

   const doSearch = (query) => {
     dispatch(fetchItemsFilterAllByCategory(query, items));
   };

   const routeToService = (serviceData) => {
     // will fetch items by service. need to change
     // dispatch(fetchAllContentByProvider(network?.call , serviceID.id));
     // dispatch(selectItem(serviceID));
     dispatch(subscriptions.selectService(serviceData));
     navigate(PWA_ROUTES.SERVICE, { state: { servicedata: serviceData } });
     //navigate('/other-page', { state: { id: 7, color: 'green' } });
   };

   // const bannerClick = (itemIn) => {
   //   dispatch(subscriptions.selectItem(itemIn));
   // };

   useEffect(() => {
     if (selectedItem !== null) {
       navigate(PWA_ROUTES.CONTENT_INFO);
     }
   }, [selectedItem]);

   const RenderMenuFilteredCategories = ({ contents }) => {
     if (contents.items.length == 0)
       return <EmptyPage>{"No results found"}</EmptyPage>;
     return (
       <div style={{ paddingTop: 30 }}>
         <LandingCategory
           key={contents?.items[0].itemType}
           viewMoreAction={undefined}
           title={null}
           items={contents?.items}
           // adsProperties={undefined}
           appPreState={appState}
           isVertical={false}
         />
       </div>
     );
   };

   const handleOnScrollHorizontal = (e, items) => {
     const closeToEnd =
       e.target.lastElementChild.offsetLeft - e.target.scrollLeft;
     if (closeToEnd < 300) {
       console.log("Call next page", items);
       // Playgination.Next(currentPage)
     }
   };

   const RenderCategories = ({ offers }) => {
     const isNotEmpty = offers?.items?.some((_item) => _item.data?.length > 0);

     if (!isNotEmpty) return <EmptyPage>{"No result found"}</EmptyPage>;

     return offers.items.map((offer, index) => {
       if (offer?.data?.length === undefined || offer?.data?.length === 0)
         return <div key={index}></div>;

       return (
         <div key={index}>
           <LandingCategory
             viewMoreAction={offer?.viewMoreAction}
             title={
               offer?.name === "movies"
                 ? language?.chosenPack?.language?.COPY?.SCREENS?.home
                     ?.category_description_movie
                 : offer?.name === "videos"
                 ? language?.chosenPack?.language?.COPY?.SCREENS?.home
                     ?.category_description_video
                 : offer?.name
             }
             items={offer?.data}
             // adsProperties={ads}
             appPreState={appState}
             onScrollHorizontal={(e) => {
               handleOnScrollHorizontal(e, offer);
             }}
           />
         </div>
       );
     });
   };

   const RenderRecommendations = ({ items }) => {
     if (!items) return <></>;

     return Object.keys(items).map((heading, index) => {
       if (items[heading]) {
         let _items = items[heading]
           .filter((_item) => _item.data && _item.data?.length > 0)
           .map((a) => a.data)
           .flat();

         if (_items.length !== 0) {
           if (
             RECOMMENDATIONS_HEADINGS[heading] ===
             RECOMMENDATIONS_HEADINGS.recent
           ) {
             _items = _items.sort(function compare(a, b) {
               const dateA = new Date(a.Dates?.created_at);
               const dateB = new Date(b.Dates?.created_at);
               return dateB - dateA;
             });
           }

           return (
             <div key={index}>
               <LandingCategory
                 title={RECOMMENDATIONS_HEADINGS[heading]}
                 items={_items}
                 appPreState={appState}
                 onScrollHorizontal={(e) => {
                   handleOnScrollHorizontal(e, items[heading]);
                 }}
               />
             </div>
           );
         }
       }
     });
   };

   const InstallHelpModal = () => {
     if (
       profile?.data?.msisdn == undefined ||
       appState?.installedPWA ||
       getMobileOperatingSystem() != "iOS"
     )
       return null;

     // position: absolute;
     // z-index: 9;
     // justify-content: center;
     // width: 100%;
     // margin-top: 20vh;

     return (
       <div
         style={{
           position: "absolute",
           zIndex: 9,
           justifyContent: "center",
           width: "100%",
           marginTop: "20vh",
         }}>
         <PureModal
           isOpen={appState?.installedPWA === false}
           replace={true}
           closeButtonPosition="header">
           {/* box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.5) */}
           <StyledIosPopup>
             <div
               style={{
                 boxShadow: "0 0 0 100vh rgba(0, 0, 0, 0.7)",
                 borderRadius: "inherit",
               }}>
               <div className="upper">
                 <img alt="" width="100" height="100" src={logo} />
                 <img
                   onClick={() => {
                     dispatch(onInstalledPWA());
                   }}
                   style={{ right: "65px", position: "absolute" }}
                   alt="Close"
                   width="19"
                   height="19"
                   src={close}
                 />
                 <p className="upper-desc">Install Play</p>
               </div>
               <div className="lower">
                 <p className="lower-desc">
                   Get the freshest content delivered directly to your device.
                   <span className="lower-desc-low">
                     Tap <img alt="" width="19" height="19" src={addHome} /> and
                     press “Add to Homescreen”
                   </span>
                 </p>
               </div>
             </div>
           </StyledIosPopup>
         </PureModal>
       </div>
     );
   };

   // const InfoModal = () => {
   //   return (
   //     <div>
   //       <Modal
   //         onClose={() => {setModalControl(false);}}
   //         show={modalControl}
   //         title={"Authentication"}>
   //         <ModalText
   //           firstSentence={
   //             "Successfully logged in from MTN Lite."
   //           }
   //         />
   //         <ModalButtons
   //           okButtonText={"Ok"}
   //           confirmButtonText={" "}
   //           onClose={() => {

   //             setModalControl(false);
   //           }}
   //         />
   //       </Modal>
   //     </div>
   //   );
   // };

   return (
     <>
       {/* <DirectionProvider direction={language?.chosenLanguage === 'arabic' ? DIRECTIONS.RTL:DIRECTIONS.LTR}> */}

       <Page isBottomBar={true}>
         {modalPending && (
           <StyledBottomPopup>
             <p style={{ fontSize: 14 }}>Your subscription is in progress.</p>
             <img
               onClick={() => switchPendingState()}
               alt=""
               width="19"
               height="19"
               src={close}
             />
           </StyledBottomPopup>
         )}
         {/* <Paginationts/> */}
         <TopNav
           title={
             language?.chosenPack?.language?.COPY?.SCREENS?.home
               ?.header_bar_title
           }
           searchInputCallBack={setQuery}
         />
         {/* {items.banners.length > 0 && (
          <>
            <Banner
              buttonOnClick={() => bannerClick(items?.banners[0])}
              bannerSummary={items?.banners}
              imageUrl={
                items?.banners && items?.banners?.length > 0
                  ? items?.banners[0]?.imageUrl
                  : ""
              }
            />
          </>
        )} */}
         <BannerCarousel />
         <InstallHelpModal />
         {/* <InfoModal /> */}

         {items?.items.length > 0 && (
           <CategoryMenu
             onSaveMenuState={(menuState) => {
               dispatch(appStateAction.saveHomeMenuState(menuState));
             }}
             savedDefaultState={appState?.menuState?.defaultState}
             saveDepthState={appState?.menuState?.depth}
             saveApiPayload={appState?.menuState?.apiPayload}
           />
         )}

         {items?.channelsData?.length && (
           <div style={{ marginBottom: "10px", marginLeft: 20, marginTop: 20 }}>
             <p
               style={{
                 fontSize: "20px",
                 lineHeight: "26px",
                 textTransform: "capitalize",
                 fontWeight: "bold",
               }}>
               Channels
             </p>
           </div>
         )}

         <div
           style={{
             display: "flex",
             marginBottom: "10px",
             overflow: "scroll",
             marginLeft: 10,
           }}>
           {items?.channelsData?.map((res, index) => {
             return (
               <div
                 key={index}
                 onClick={() => routeToService(res)}
                 style={{ marginLeft: 10, marginTop: 20 }}>
                 <ChannelCard data={res} />
               </div>
             );
           })}
         </div>

         {/* {items.channelsData !== null ? (
          <div onClick={routeToService} style={{ marginLeft: 20, marginTop: 20}}>
            <ChannelCard data = {items.channelsData}/>
          </div>
         
        ) : (
          <></>
        )} */}

         {recommendations.recommendations && menuState ? (
           <></>
         ) : (
           <RenderRecommendations items={recommendations.recommendations} />
         )}
         {filteredMenuItems.items.length > 0 || menuState ? (
           <RenderMenuFilteredCategories contents={filteredMenuItems} />
         ) : items.items.length > 0 ? (
           <RenderCategories key={"items"} offers={items} />
         ) : (
           <img alt="" width={width} height={height} src={loaderskel} />
         )}
         {/* <Ads data={adsMbks} form/> */}
       </Page>
       {/* </DirectionProvider> */}
     </>
   );
 };

const EmptyPage = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default HomeScreen;
  import {EVENT, PARAM} from "./helpers";

export const login_screen = {
	eventName: EVENT.VIEW_SCREEN,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "discovery",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "login",
		},
	],
};

export const otp_screen =  {
	eventName: EVENT.VIEW_SCREEN,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "discovery",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "otp",
		},
	],
};


export const login_authentication = (origin) => {
		return {
			eventName: EVENT.VIEW_SCREEN,
			eventParameters: [
				{
					key: "authentication_origin",
					value: origin,
				},
			],
		};
};
  

export const otp_request = {
	eventName: EVENT.AUTHENTICATION,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "otp_request",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "login",
		},
	],
};
  
export const otp_successful = {
	eventName: EVENT.AUTHENTICATION,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "otp_success",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "login",
		},
	],
};

export const otp_fail = {
	eventName: EVENT.AUTHENTICATION,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "otp_fail",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "login",
		},
	],
};

export const otp_resend = {
	eventName: EVENT.AUTHENTICATION,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "otp_resend",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "otp",
		},
	],
};

export const auth_request = {
	eventName: EVENT.AUTHENTICATION,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "authenticate_request",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "otp",
		},
	],
};

export const auth_sucess = {
	eventName: EVENT.AUTHENTICATION,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "authenticate_success",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "otp",
		},
	],
};

export const auth_fail= {
	eventName: EVENT.AUTHENTICATION,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "authenticate_fail",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "otp",
		},
	],
};




export const EVENT = {
	ERROR: "error",
	PURCHASE: "purchase",
	SUBSCRIBE: "subscribe",
	TAP: "tap",
	VIEW_MODAL: "view_modal",
	VIEW_SCREEN: "view_screen",
	AUTHENTICATION: "authentication"
};

export const PARAM = {
	KEY: {
		CATEGORY: "category",
		CHECKOUT_TYPE: "checkout_type",
		CONTENT_CATEGORY: "content_category",
		CONTENT_PRICE_TIER: "content_price_tier",
		CONTENT_PUBLISHER: "content_publisher",
		CONTENT_SUBSCRIPTION_PRICE: "content_subscription_price",
		CONTENT_TITLE: "content_title",
		CONTENT_VENDOR: "content_vendor",
		ERROR_CODE: "error_code",
		ERROR_MESSAGE: "error_message",
		FAQ_TITLE: "faq_title",
		FEATURE: "feature",
		MODAL: "modal",
		PAYMENT_METHOD: "payment_method",
		SCREEN: "screen",
		SORT_OPTION: "sort_option",
		SORT_ORDERING: "sort_ordering",
		TAP_LABEL: "tap_label",
		TAP_TARGET: "tap_target",
		OTP_REQUEST: "otp_request",
		OTP_RESEND: "otp_resend",
		LOGIN_REQUEST: "login_request",
		EXTERNAL_LINK: "external_link",
		SERVICE_PROVIDER: "service_provider",
		
	},
};

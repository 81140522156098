import { StyledBanner } from "./Banner.styles";
import {HomeScreen as events} from "../../../analytics/events";
import {useDispatch} from "react-redux";

const Banner = ({isOpacity}) => {


	const dispatch = useDispatch();

	const bannerClick = () => {
		dispatch({
			type: "ANALYTICS/TRACK_EVENT",
			event: events.banner_hit_home_screen,
		  });
		  window.open("https://www.gameloft.com/");

	};

	return (
		<StyledBanner onClick={()=> bannerClick()} isOpacity={isOpacity} image={"https://raw.githubusercontent.com/MTN-Group/thirdparty_icons/main/MTN-GH-LPBanners.jpeg"}>

		</StyledBanner>
	);
	

	// return (
	// 	<StyledBanner isOpacity={isOpacity} image={imageUrl}>
			
	// 		<AdsPace>
	// 		<p>{bannerSummary? bannerSummary[0]?.title : ''}</p>
	// 		<BannerButton onClick={buttonOnClick}>VIEW INFO</BannerButton>
	// 		</AdsPace>
			
	// 	</StyledBanner>
	// )
};

export default Banner;

import {EVENT, PARAM} from "./helpers";

export const terms_and_conditions_screen = {
	eventName: EVENT.VIEW_SCREEN,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "discovery",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "terms_and_conditions",
		},
	],
};

import {EVENT, PARAM} from "./helpers";

export const home_screen = {
	eventName: EVENT.VIEW_SCREEN,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "discovery",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "home",
		},
	],
};



export const banner_hit_home_screen = {
	eventName: EVENT.TAP,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "banner_tap",
		},
		{ 
			key: PARAM.KEY.EXTERNAL_LINK,
			value: "https://www.gameloft.com/"
		}
	],
};

export const content_hit_tap = (id,title, vendor_id, provider ) => {
	return {
		eventName: EVENT.TAP,
		eventParameters: [
			{
				key: PARAM.KEY.FEATURE,
				value: "content_tap",
			},
			{
				key: "content_id",
				value: id,
			},
			{
				key: "content_title",
				value: title,
			},
			{
				key: "content_vendor",
				value: vendor_id,
			},
			{
				key: "service_provider",
				value: provider,
			},

  
		],
	};
};











import { motion } from "framer-motion";
import styled from "styled-components";

export const StyledSubscribeSection = styled(motion.div)`
	min-height: 64px;
	width: 100vw;
	/* margin: 0; */
	background: #000;
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: auto;
	position: fixed;
    bottom: 64px;
`;

export const StyledButtonLoading = styled(motion.div)`
  min-width: 25px;
  min-height: 25px;

  border: 4px solid ${({ primary }) => (primary ? "#fff" : "#333")};
  border-bottom-color: transparent;
  border-radius: 45px;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;







export const StyledButtonLoadingPlain = styled(motion.div)`

max-height: ${({theme}) => theme?.heights[3] ?? "48px"};
/* max-width: ${({theme}) => theme?.widths[1] ?? "192px"}; */
min-width: 260px;
padding: 16px 24px;
height: 40px;
font-family: ${({theme}) => theme?.fonts[0] ?? "MTNBrighterSansRegular"};
font-style: normal;
font-weight: 700;
font-size: ${({theme}) => theme?.size?.f3 ?? "12px"};
line-height: 14px;
display: flex;
flex-direction: ${({isFullscreen}) => !isFullscreen ? "row" : "column"};
margin-bottom: ${({isFullscreen}) => isFullscreen ? "24px" : "0"};
align-items: center;
text-transform: uppercase;
justify-content: center;
text-align: center;
background-color: grey;
border: 1px solid;
border-radius: 50px;
color: ${({isOutline}) => isOutline ? "#FFCB05" : "#000"};
text-transform: uppercase;
text-decoration: none !important;
`;



import {EVENT, PARAM} from "./helpers";

export const error_view_screen = {
	eventName: EVENT.VIEW_SCREEN,
	eventParameters: [
		{
			key: PARAM.KEY.FEATURE,
			value: "discovery",
		},
		{
			key: PARAM.KEY.SCREEN,
			value: "error",
		},
	],
};



import {useState} from "react";
import {SubComponent} from "..";
import {HeaderTabs} from "./HeaderTabs";
import {StyledTabbedList} from "./TabbedList.styles";
import {useSelector} from "react-redux";


const TabbedList = ({ items, sort_events = {}, tab_events = {}, initPosition=0}) => {

	const {language} = useSelector((state) => state);


  
	const tabs=[language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections_content?.subscriptions?.active, language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections_content?.subscriptions?.inactive, language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections_content?.subscriptions?.pending];
	const [chosenTab, setChosenTab] = useState(tabs[initPosition]);
  
	const mTitle = capitalizeFirstLetter(`${chosenTab}`.toLowerCase());


    


	function capitalizeFirstLetter(string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	}


	return (
		<StyledTabbedList>
			<HeaderTabs
				toggleTabs={setChosenTab}
				tabs={tabs}
				events={tab_events}
				initPosition={initPosition}
			/>
			<SubComponent
				title={`${items?items[chosenTab]?.length ?? 0 : 0} ${mTitle} ${language?.chosenPack?.language?.COPY?.SCREENS?.manage?.sections_content?.subscriptions?.screen_title.toLowerCase()}`}
				items={items?items[chosenTab] : []}
				events={sort_events}
				chosenTab={chosenTab}
			/>
		</StyledTabbedList>
	);
};

export default TabbedList;

/* eslint-disable no-unused-vars */
import { useState } from "react";
import { useSelector } from "react-redux";
// import { PartnerInfo } from "..";
import { Service } from "..";
import { StyledItemInfo } from "./ItemInfo.styles";
import { SocialButtons } from "../SocialButtons";

const ItemInfo = () => {
  const selectedItem = useSelector(
    (state) => state?.subscriptions?.selectedItem,
  );
  // const [isReadMore, setIsReadMore] = useState(true);
  // const toggleReadMore = () => {
  // 	setIsReadMore(!isReadMore);
  // };

  const service = selectedItem?.service; /*|| {
    description:
      "Get access to thousands of documentaries on a variety of subjects ranging asdjhsalk asldjksaldlk laksdj.",
    detailedDescription: "",
    id: "000000000000000000000000",
    logo: "https://mtnplaystorage.blob.core.windows.net/ghana/PROD/logo/64f5c2e49c8deb9b95fe1162.jpg",
    name: "Docubay",
    region: "",
    subscribed: "",
    vendorId: "",
  };*/

  // console.log(selectedItem?.service);
  // console.log(service);

  return (
    <StyledItemInfo>
      <ReadMore>{selectedItem?.detailedDescription}</ReadMore>
      <SocialButtons />

      {/* <PartnerInfo item={selectedItem} /> */}
      <Service
        id={service?.id}
        logo={service?.logo}
        name={service?.name}
        description={service?.description}
      />
    </StyledItemInfo>
  );
};

export default ItemInfo;

const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };
  return (
    <p className="metadata">
      {isReadMore ? text?.slice(0, 100) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {text?.length > 100 ? isReadMore ? "... more" : "... less" : <></>}
      </span>
    </p>
  );
};

import {Menu, Page, TopNav} from "../../shared/components";

// import {ManageScreen as events} from "../../analytics/events";
// import zigiChatBotInit from "../../lib/zigiChatBot";

const PrivacyNoticesScreen = () => {

    const onClickOpenA = () => {
      window.open(
        "https://www.mtn.com/legal/?tablink=privacy_policy",
        "_blank",
      );
    };

    const onClickOpenB = () => {
      window.open(
        "https://mtn.com.gh/data-protection-and-privacy-statement/",
        "_blank",
      );
    };

    return (
      <>
        {/* <DirectionProvider direction={language?.chosenLanguage === 'arabic' ? DIRECTIONS.RTL:DIRECTIONS.LTR}> */}
        <Page isBottomBar={false}>
          <TopNav
            title={"Privacy Notices"}
            enableSearch={false}
            showCloseIcon={false}
            centerTitle={true}
          />
          <div style={{ margin: 25 }}>
            <p>
              This service follows the Privacy Notices of the MTN Group and MTN
              Ghana. View the Privacy Notices for your understanding.
            </p>
          </div>
          <div onClick={() => onClickOpenA()}>
            <Menu
              title={"MTN Group Privacy Notice"}
              clickRoute={null}
              description={""}
            />
          </div>
          <div onClick={() => onClickOpenB()}>
            <Menu
              title={"MTN Ghana Privacy Notice"}
              clickRoute={null}
              description={""}
            />
          </div>
        </Page>
      </>
    );
};

export default PrivacyNoticesScreen;

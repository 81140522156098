import {useState, useEffect} from "react";
import { Button } from "..";
import { StyledSubscribeSection} from "./SubscribeSection.styles";
import {useSelector, useDispatch} from "react-redux";
import {fetchPaymentMethods} from "../../../utils/redux/actions/subscription.action";
import {PWA_ROUTES} from "../../../constants/appConstants";
import {clearSubscription} from "../../../utils/redux/actions/subscription.action";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import {  ModalButtons, ModalText, Modal } from "../../components";

import {SubscribeSection as events} from "../../../analytics/events";

const SubscribeSection = ({ buttonText}, ref ) => {
	const { loginWithRedirect, isAuthenticated } = useAuth0();

	const dispatch = useDispatch();
	const [loading, setLoading] = useState(false);

	
	const [ isNoBillingTypes, setIsNoBillingTypes] = useState(false);

	const [handleInitiate, setHandleInitiate] = useState(false);
	const navigate = useNavigate();

	const {network, profile} = useSelector((state) => state);
	 
	const {
		selectedCharge,
		selectedItem,
		paymentMethods,
		error
	  } = useSelector((state) => state?.subscriptions);

	const handleAuth0Login = () =>{
		localStorage.setItem(
      "state",
      JSON.stringify({
        route: `${location.pathname}`,
        extra: { item: selectedItem, selectedCharge: selectedCharge },
      }),
    );
		loginWithRedirect({redirectUri:`${window.location.origin}/auth/callback`});
	};

	const handleChange = () => {

		if(!isAuthenticated){	
			dispatch({
				type: "ANALYTICS/TRACK_EVENT",
				event: events.authenticationAttempt,
			});

			handleAuth0Login();
			return;
		}

		if(profile?.data?.msisdn && profile.data){
			if(selectedItem.subscribed === "Active"){
				navigate(PWA_ROUTES.CONTENT_PLAYER);
			} else {
				dispatch(fetchPaymentMethods(network?.call));
				setLoading(true);
			}
			
			 setHandleInitiate(true);
		}else{
			//navigate(PWA_ROUTES.LOGIN)
			handleAuth0Login();
		}
	  };

	  useEffect(() => {
		setHandleInitiate(false);
		
		if(selectedItem?.billingTypes == null) {
			setIsNoBillingTypes(true);
		} else {
			setIsNoBillingTypes(false);
		}
		
	}, []);

	useEffect(() => {

		if(paymentMethods?.length && selectedItem?.billingTypes == null) {
			setLoading(false);
		}
		
	
		if (paymentMethods?.length && (selectedItem?.billingTypes != null ) && handleInitiate && selectedItem.subscribed !== "Active") {
			navigate(PWA_ROUTES.PAYMENTS);
		}
	}, [paymentMethods]);

	useEffect(() => {
		if(handleInitiate && selectedItem?.subscribed === "Active") {
			navigate(PWA_ROUTES.CONTENT_PLAYER);
		}
	}, [handleInitiate]);
	
	useEffect(() => {
		if(error && error != "") {
			  dispatch(clearSubscription());
			  navigate(PWA_ROUTES.GENERIC_ERROR);
		}
	}, [error]);	

	return (
		<>
		<div>
			<Modal
			  onClose={() => {}}
			  show={isNoBillingTypes}
			  title={"Billing Type"}>
			  <ModalText
				firstSentence = {
				  "No billing types found, Please try again."
				}
			  />
			  <ModalButtons
				okButtonText={"Ok"}
				confirmButtonText={" "}
				onClose={() => {
				  setIsNoBillingTypes(false); 	
				  navigate(PWA_ROUTES.HOME);
				}}
			  />
			</Modal>
		</div>
		<StyledSubscribeSection ref={ref}>
			<div  style={{textDecoration: "none", color: "#ffff", width: "100%", marginLeft: "24px", marginRight:"24px"}}>
		   <Button loading={loading} caption={buttonText} clickHandler={handleChange} />
			</div>
		</StyledSubscribeSection>
		</>
	);
};

export default SubscribeSection;


import { StyledChannelCard } from "./ChannelCard.styles";

const ChannelCard = ( data ) => {


    return (
      <>
        <StyledChannelCard>
          <div
            style={{
              width: 240,
              height: 120,
              // padding: "10px 20px",
              backgroundColor: "#000",
              borderTopLeftRadius: 8,
              borderTopRightRadius: 8,
              border: "1px solid #424242",
              borderRadius: 8,
              overflow: "hidden",
              backgroundImage: `url(${data.data.logo})`,
              backgroundRepeat: "no-repeat",
              backgroundSize: 100,
              backgroundPosition: "center",
            }}>
            {/* <img
              style={{
                height: 100,
                width: "auto",
                verticalAlign: "middle",
                // width: "150px",
                borderTopLeftRadius: 8,
                borderTopRightRadius: 8,
              }}
              src={data.data.logo}
            /> */}
          </div>
          <p
            style={{
              width: "150px",
              // backgroundColor: "#111111",
              padding: "10px",
              paddingLeft: 0,
              color: "white",
              fontSize: "13px",
              borderBottomLeftRadius: 8,
              borderBottomRightRadius: 8,
              fontFamily: "MTN-Bold",
              fontWeight: 500,
            }}>
            {data.data.name}
          </p>
        </StyledChannelCard>
      </>
    );

};

export default ChannelCard;
export const LANDING_CATEGORIES = {
	VIDEOS: "VIDEOS",
	MUSIC: "MUSIC",
	MOVIES: "MOVIES",
	GAMES: "GAMES"
};

export const CONTENT_PROVIDERS = {
	WIFLIX: "Wi-Flix",
	DV: "PlayVOD"
};

export const PUSH_NOTIFICATION ={
	STORAGE_KEY: "playGeneratedNotificationPayload",
};

export const INPUT_EVENTS = {
	DELETE_EVENT: "deleteContentBackward",
	INSERT_EVENT: "insertText"
};

export const PWA_ROUTES = {
  ROOT: "/",
  LOGIN: "/login",
  LOGOUT: "/logout",
  AUTH_CALLBACK: "/auth/callback",
  LOGIN_REDIRECT: "auth/loginRedirect",
  PRIVACY_NOTICE: "/privacyNoticesScreen",
  OTP: "/Otp",
  HOME: "/home",
  SERVICE: "/serviceScreen",
  PAYMENTS: "/payments",
  CONTENT_INFO: "/ContentInfo",
  COMING_SOON: "/comingsoon",
  SERVICE_DETAILS_SCREEN: "/ServiceDetailsScreen",
  SERIES: "/series",
  SUBSCRIBE_CONFIRM: "/myplay",
  ITEMS: "/items/:id",
  TOPUP: "/topup",
  TOPUP_METHOD: "/methods",
  TOPUP_VOUCHER: "/voucher",
  SUBSCRIBE_SUCCESS: "/success",
  SUBSCRIBE_FAIL: "/fail",
  CATEGORIES: "/categories",
  MANAGE: "/settings",
  SUBSCRIPTIONS: "/subscriptions",
  PURCHASES: "/purchases",
  ACTIVE_SUBSCRIPTIONS: "/activesubscriptions",
  INACTIVE_SUBSCRIPTIONS: "/inactivesubscriptions",
  PENDING_SUBSCRIPTIONS: "/pendingsubscriptions",
  PROFILE: "/profile",
  FAQ: "/help",
  TERMS_AND_CONDITIONS: "/terms",
  MY_PLAY: "/myplay",
  CONTENT_PLAYER: "/ContentPlayer",
  LOADING: "/Loading",
  TRANSACTION_RES: "/TransactionResScreen",
  GENERIC_ERROR: "/generic",
  COMPLETE_ENRICH: "/complete/enrichment",
  PROTECTED: "/protected",
  SEARCH: "/search",
  TEST_AYOBA_VIDEO: "/test/ayoba/video",
  TEST_AYOBA_MSISDN: "/test/ayoba/msisdn",
  CONSENT: "/consent",
};

export const ITEM_TYPES = {
  MOVIE: "movies",
  MUSIC: "music",
  GAME: "games",
  VIDEO: "videos",
  SPORTS: "sports",
  TRENDING: "trending",
  OFFERS: "offers",
  LIFESTYLE: "lifestyle",
};

export const ITEM_TIERS = {
  FREE: "free",
  SUBSCRIPTION: "subscription",
  PURCHASE: "purchase",
};

export const RECOMMENDATIONS_HEADINGS = {
  popular_content: "Popular on Play",
  recent: "Recently added  ",
  trending: "Trending",
};

const HOST = window.location.host;
const urls = {
  ENDPOINT: {
    contentService: HOST.includes("uat")
      ? "https://content.uat.mtnplay.com"
      : HOST.includes("dev") || HOST.includes("localhost")
      ? "https://content.dev.mtnplay.com"
      : HOST.includes("sit")
      ? "https://content.sit.mtnplay.com"
      : "https://content-prod.mtnplay.com",
    subscriptionService: HOST.includes("uat")
      ? "https://subscription.uat.mtnplay.com"
      : HOST.includes("dev") || HOST.includes("localhost")
      ? "https://subscription.dev.mtnplay.com"
      : HOST.includes("sit")
      ? "https://subscription.sit.mtnplay.com"
      : "https://subscription-prod.mtnplay.com",
    userService: HOST.includes("uat")
      ? "https://user.uat.mtnplay.com"
      : HOST.includes("dev") || HOST.includes("localhost")
      ? "https://user.dev.mtnplay.com"
      : HOST.includes("sit")
      ? "https://user.sit.mtnplay.com"
      : "https://user-prod.mtnplay.com",
  },
};
export const uri = urls.ENDPOINT;

const analyticsUrls = {
  ENDPOINT: {
    environment: HOST.includes("uat")
      ? "uat"
      : HOST.includes("prod")
      ? "live"
      : HOST.includes("sit")
      ? "uat"
      : "live",
    // endpoint: HOST.includes('uat') ? 'https://uat-mtn-play-gh-dot-mymtn-nextgen-ghana.ew.r.appspot.com/extract' : HOST.includes('prod') ? 'https://live-mtn-play-gh-dot-mymtn-nextgen-ghana.ew.r.appspot.com/extract' : HOST.includes('sit') ? 'https://uat-mtn-play-gh-dot-mymtn-nextgen-ghana.ew.r.appspot.com/extract' : 'https://uat-mtn-play-gh-dot-mymtn-nextgen-ghana.ew.r.appspot.com/extract',
    endpoint: HOST.includes("uat")
      ? "https://content.uat.mtnplay.com/api/sendanalytics"
      : HOST.includes("dev") || HOST.includes("localhost")
      ? "https://content.dev.mtnplay.com/api/sendanalytics"
      : HOST.includes("sit")
      ? "https://content.sit.mtnplay.com/api/sendanalytics"
      : "https://content-prod.mtnplay.com/api/sendanalytics",
  },
};

export const analyticUri = analyticsUrls.ENDPOINT;

// api/sendanalytics

export const ZIGI_SCRIPT_URL =
  HOST.includes("dev") || HOST.includes("dev")
    ? "https://chatbot-dev.mtn.com.gh/bm/"
    : "https://chatbot-stg.mtn.com.gh/bm/";

export const COUNTRIES = Object.freeze({
  "+260": {
    name: "Zambia",
    url: "https://www.mtn.zm/play/",
    abbreviation: "ZM",
    redirect: true,
  },
  "+245": {
    name: "Guinea Bissau",
    url: "https://mtngbissau.com/play/",
    abbreviation: "GW",
    redirect: true,
  },
  "+234": {
    name: "Nigeria",
    url: "https://www.mtn.ng/play/",
    abbreviation: "NG",
    redirect: true,
  },
  "+237": {
    name: "Cameroon",
    url: "https://mtn.cm/play/",
    abbreviation: "CM",
    redirect: true,
  },
  "+231": {
    name: "Liberia",
    url: "https://lonestarcell.com/digital/",
    abbreviation: "LR",
    redirect: true,
  },
  "+256": {
    name: "Uganda",
    url: "https://www.mtn.co.ug/mtn-play/",
    abbreviation: "UG",
    redirect: true,
  },
  "+249": {
    name: "Sudan",
    url: "https://www.mtn.sd/mtn/play/",
    abbreviation: "SD",
    redirect: true,
  },
  "+233": {
    name: "Ghana",
    url: "https://mtnplay.com",
    abbreviation: "GH",
    redirect: false,
  },
  "+27": {
    name: "South Africa",
    url: "https://play.mtn.co.za/",
    abbreviation: "ZA",
    redirect: true,
  },
  "+55": {
    name: "Brazil",
    url: "https://www.mtn.zm/play/",
    abbreviation: "BR",
    redirect: false,
  },
});